///////////// Importing Dependencies ///////////
import React from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";

//////////// Importing Redux, Actions and Selectors //////////
import { KP, DOCVIZ } from "redux/constants";
import {
  notifyWarning,
  notifyError,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { actions as KPActions } from "redux/actions/knowledgepage/knowledgepage_actions";
import { getTagsSuggestions, resetTagsSuggestions, getUniqueTitleConfirmation, resetUniqueTitleConfirmation, getSubjectSearchSuccess, setTitleFieldsUpdateState } from "redux/actions/knowledgepage/knowledgepage.actions";
import { uploadStart } from "redux/actions/upload/upload.actions";
import { resetDocvizMetadata } from "redux/actions/docviz/docviz.actions";
import { getCasesMetaDetails } from 'redux/actions/casecode/case.action';
import { getTaskRegister, getTaggingStatus, recommendedTagsReset, postFeedbackRecommendedTags } from "redux/actions/recommendedtagging/recommendedtagging.actions";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';

import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";
import { selectors as uploadSelectors } from "redux/reducers/upload/upload_reducer";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as recommendedTaggingSelectors } from "redux/reducers/recommendedtagging/recommendedtagging_reducer";
import { selectors as caseSelectors } from 'redux/reducers/casecode/case_reducer';
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";

//////////// Importing Components //////////
import FooterWorkflow from "components/shared/FooterWorkflow";
import { Layout } from "containers/Layout";
import { KPStep1 } from "containers/KnowledgePage/KPStep1";
import { KPStep2 } from "containers/KnowledgePage/KPStep2";
import { KPStep3 } from "containers/KnowledgePage/KPStep3";
import { KPSideBar } from "containers/KnowledgePage/KPSideBar";
import { WarningModal } from "components/WarningModal/WarningModal";
import { CloneModal } from "components/CloneModal";
import WorkflowWarningSteps from 'components/shared/WorkflowWarningSteps'

//////////// Importing Constants & Helper //////////
import {
  getFileExtension,
  isSectorTopicOwnerSelected,
  getDocumentPath,
  getUtcDate,
  replaceCharacters,
  getQueryParamIsRpa,
  getIsRpaReview,
  areObjectsEqual,
  copyObject,
  isFileTypePPT,
  findNonStopWords,
  getmyCurrentLocation,
  getLengthOfMultiArrayWithPoly,
  getDocvizSupportedFileState,
  isOwnerMdp,
  conditionsApprovalMdp
} from "utils/helpers/helpers";
import { isValidInput } from "utils/helpers/validation";
import ANALYTICS from "utils/analytics/analytics";
import { PAGES, PRIMARY_CATEGORY, DTMRULE, TRIGGERS, SCREEN_NAMES, MATERIAL_STATUS } from "utils/analytics/analytics_constants";
import GLOBAL_CONFIG from "config";
import LABELS from "labels";
import CONFIG from "./KnowledgePage.config";
import CONSTANTS from "globalConstants";
import { getUserId } from "utils/auth/auth";
import { getDescriptionCharLength } from "components/shared/RichEditor/RichEditor.helper.js"

//////////// Importing Images ////////
import WarningIcon from "assets/images/warning/btn-warning.svg";
import SuccessIcon from "assets/images/success/btn-success.svg";

//////////// Importing scss Files /////////
import "./KnowledgePage.scss";

const { ENTITLEMENTS: { KNOWLEDGE_CONTRIBUTIONS, VIEW, PUBLISH_CONTENT, PREVIEW_DOWNLOAD, TAG_FRAMEWORK, PREVIEW_DOWNLOAD_R } } = CONSTANTS;

const { validationDictionary } = CONFIG;

const { STEP1, STEP2, STEP3, VALIDATION_KEY } = CONSTANTS;

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME } } = GLOBAL_CONFIG;

const { THUMBSUPDOWN: { THUMBSUP } } = LABELS;
const { SITECORE_XM_ENABLED } = GLOBAL_CONFIG;

class KnowledgePageContainer extends React.PureComponent {
  constructor(props) {
    super();
    const {
      match: { params },
      history: { location }
    } = props;
    const search = !!location ? location.search : "";
    // it helps to know the modal option chosen by user - url or file
    let modal_option = "";
    if (!!search) {
      const options = search.split('=');
      modal_option = options[1];
    }


    const { step, kpid } = params;

    this.state = {
      isZoomRightSec: false,
      activeStep1: step === STEP1,
      activeStep2: step === STEP2,
      activeStep3: step === STEP3,
      userId: "",
      mainKPState: this.getMainKPstate(false, {}, modal_option),
      errorState: {},
      kpfileState: {},
      kpfileUploadStatus: false,
      isWModalVisible: false,
      isPublishModalVisible: false,
      isFileExist: false,
      kpMode: modal_option,
      comment: '',
      fileChanged: false,
      showCloneModal: false,
      initiatePolling: true,
      titleConfig: {
        showUniqueTitleError: false,
        preserveTitle: ''
      },
      descriptionLength: 0,
      step1NextBtnClicked: false,
      isApprovedByMdpShow: false
    };

    this.updateMetaData = debounce(this.updateMetaData.bind(this), CONFIG.debounceTime);


    if (kpid) {
      const {
        KPActions: { getMetaDataKP }
      } = props;
      getMetaDataKP(kpid)
    }

  }

  isReview = () => {
    const { isPublishContentEntitlement } = this.props;
    return isPublishContentEntitlement === undefined ? false : !isPublishContentEntitlement;
  }

  getMainKPstate = (resData, defaultLanguage = {}, creationFlow = "") => {
    let practiseareas = [{ 'ipaTags': [], 'fpaTags': [], 'bcgInternals': [] }];
    let paTagsOwner = false;

    // data collection for step3 field: `Designate ONE Sector/Topic as the OWNER`
    if (resData?.ipaTags || resData?.fpaTags || resData?.bcgInternals) {
      practiseareas = [{ 'ipaTags': resData?.ipaTags || [], 'fpaTags': resData?.fpaTags || [], 'bcgInternals': resData?.bcgInternals || [] }]

      // calculate the owner flag for custom field validation of "Step 3 : `Designate ONE Sector/Topic as the OWNER`
      paTagsOwner = isSectorTopicOwnerSelected(practiseareas);
    }

    //renaming the keys as component consumes name-value pairs, api sends title-url pairs
    let links = resData?.additionalURLs || [];
    let renamedLinks = [];
    if (links.length) {
      renamedLinks = links.map(link => ({ name: link.title, value: link.url }));
    }

    // derive URL param to set isURLAdded
    let urlParamData = "0";
    if (!!creationFlow) {
      urlParamData = creationFlow === "url" ? "1" : "0";
    }

    const data = {
      //step1 fields
      title: resData?.title || "",
      description: resData?.description || "",
      descriptionLength: resData?.descriptionLength || 0,
      contacts: resData?.additionalContacts || [],
      additionalURLs: renamedLinks || [],
      additionalContacts: resData?.additionalContacts || [],
      mdpName: resData?.attachment?.case?.mdps || [],
      originalRequestor: resData?.originalRequestor ? [resData?.originalRequestor?.toString()] : [],
      materialUrl: resData?.materialUrl || {
        "address": "",
        "title": ""
      },
      isURLAdded: resData?.isURLAdded || urlParamData,
      authors: resData?.attachment?.authors || [],
      file: {
        name: resData?.attachment?.fileName || "",
        size: resData?.attachment?.fileSize || 0,
      },
      documentPath: resData?.attachment?.documentPath || "",
      documentReplacementDate: resData?.documentReplacementDate || "",
      kpOwner: resData ? (resData.owner ? [resData?.owner] : 0) : [this?.state?.userId], // ==> [null]
      isRestricted: resData?.isRestricted || false,
      relatedToCMP: resData?.relatedToCMP || false,
      relatedToGTM: resData?.relatedToGTM || false,
      isCMPUser: resData?.isCMPUser || false,
      isGTMUser: resData?.isGTMUser || false,
      uniqueId: resData?.uniqueId || "",
      isRelatedToCase: resData?.attachment?.isRelatedToCase || false,
      selectedSuggestedAuthors: resData?.selectedSuggestedAuthors || [],
      caseNumbers: resData?.attachment?.case?.caseNumbers || [],
      id: resData?.id || "",
      practiseAreaTags: practiseareas || [],
      paTagsOwner: paTagsOwner || false, // for custom validation on step3: field Designate ONE Sector/Topic as the OWNER
      regions: resData?.regionCountries || [],
      searchDiscoveryTags: resData?.subjects || [],
      otherlanguage: resData ? (resData?.attachment?.language?.name ? resData?.attachment?.language : defaultLanguage) : defaultLanguage,
      officeLocation: resData?.office?.name ? [resData?.office] : [],
      knowledgeType: resData?.contentType?.name ? resData?.contentType : {},
      isInternal: resData?.contentType?.isInternal ? resData?.contentType?.isInternal : false,
      internalOfficeLocation: resData?.InternalOffice || [],
      status: resData?.status || "",
      version: resData?.version || 1,
      isPostPublish: false,
      isTitleHasSubjects: resData?.isTitleHasSubjects || false,
      isMdpApprovalRequired: resData?.isMdpApprovalRequired || "",
      isMdpApprovalValidationRequired: resData?.isMdpApprovalValidationRequired || false,
    }
    return data;
  }

  getSendKPstate = (data, status, isFinalSubmit) => {

    //renaming the keys as component sends name-value pairs, api needs title-url pairs
    let links = data?.additionalURLs || [];
    const { KNOWLEDGE_STATUS: { REVIEW, PUBLISHED }, IS_MDP_APPROVED_NEWFIELD_KP } = GLOBAL_CONFIG;
    let renamedLinks = [];
    if (links.length) {
      renamedLinks = links.map(link => ({ title: link.name, url: link.value }));
    }
    const { tagsSuggestionsList } = this.props;
    const res = {
      title: data.title,
      description: data.description,
      descriptionLength: data.descriptionLength,
      attachment: {
        case: {
          mdps: data.mdpName || [],
          caseNumbers: data.caseNumbers || []
        },
        documentPath: data.documentPath || "",
        isRelatedToCase: data.isRelatedToCase,
        authors: data.authors || [],
        fileName: data.file?.name || "",
        fileSize: data.file?.size || 0,
        language: data?.otherlanguage ? data?.otherlanguage : {}
      },
      originalRequestor: data?.originalRequestor?.toString(),
      documentReplacementDate: data.documentReplacementDate,
      owner: data?.kpOwner[0],
      isRestricted: data.isRestricted,
      relatedToCMP: data.relatedToCMP,
      relatedToGTM: data.relatedToGTM,
      uniqueId: data.uniqueId,
      selectedSuggestedAuthors: data.selectedSuggestedAuthors || [],
      id: data?.id,
      ipaTags: data.practiseAreaTags[0]?.ipaTags || [],
      fpaTags: data.practiseAreaTags[0]?.fpaTags || [],
      bcgInternals: data.practiseAreaTags[0]?.bcgInternals || [],
      prefillEmail: [],
      status,
      updatedBy: data?.id ? this.state.userId : 0,
      regionCountries: data?.regions || [],
      additionalContacts: data?.additionalContacts || [],
      additionalURLs: renamedLinks || [],
      office: data?.officeLocation.length ? data.officeLocation[0] : {},
      InternalOffice: data?.internalOfficeLocation || [],
      subjects: data?.searchDiscoveryTags.length ? data?.searchDiscoveryTags : [],
      contentType: Object.keys(data?.knowledgeType).length ? data?.knowledgeType : {},
      materialUrl: data?.materialUrl || [],
      isURLAdded: data?.isURLAdded || "0",
      version: data?.version || 1,
      isTitleHasSubjects: this.checkIsTitleHasSubjects(data),
      isMdpApprovalRequired: data?.isMdpApprovalRequired || "",
      isMdpApprovalValidationRequired: data?.isMdpApprovalValidationRequired,
      polyHierarchiLength: getLengthOfMultiArrayWithPoly([{
        ipaTags: data.practiseAreaTags[0]?.ipaTags || [],
        fpaTags: data.practiseAreaTags[0]?.fpaTags || [],
        bcgInternals: data.practiseAreaTags[0]?.bcgInternals || []
      }])
    }

    const isRpaReview = getIsRpaReview();
    if (isRpaReview) {
      res["isrpareview"] = true;
    }

    if (!data?.id) {
      // send these attributes to api only once(first time when kpid does not exist)
      res["createdBy"] = this.state.userId;
    }

    if (status === REVIEW && isFinalSubmit) {
      res["sendforReview"] = true;
    }

    if (status === PUBLISHED && isFinalSubmit && tagsSuggestionsList) {
      res["suggestedTags"] = tagsSuggestionsList;
    }

    if (status === PUBLISHED && (data?.version > 1)) {
      res["postPublish"] = data?.isPostPublish;
    }

    if (status !== PUBLISHED && isFinalSubmit && this.isApprovalMDPFieldShow() && IS_MDP_APPROVED_NEWFIELD_KP) {
      res["isMdpApprovalValidationRequired"] = this.isApprovalMDPFieldShow();
    }

    if (isFinalSubmit && (status === PUBLISHED || status === REVIEW)) {
      let { REMOVE_TAGS_REGX } = GLOBAL_CONFIG;
      /*
        In case of blank description "<p></p>\n" this is going from UI side,
        if description is blank and "<p></p>\n" this string is coming  in description
        then we have to set it back to "" blank string so that we get a validation error from backend side
      */
      let { description } = data;
      try {
        if (!description.replace(REMOVE_TAGS_REGX, "").trim().length) {
          res.description = "";
          res.descriptionLength = 0
        } else {
          res.descriptionLength = getDescriptionCharLength(description);
        }
      } catch (error) {
        console.error('getSendKPstate func description error ', error)
      }
    }
    return res;
  }

  /**
   * IF title is updated then we are returing isTitleHasSubjects flag else
   * data.isTitleHasSubjects, which means title is not updated and
   * whatever the repsonse is coming from api we are sending it back in the post api
   * @param {*} data 
   * @returns 
   */
  checkIsTitleHasSubjects = (data) => {
    const { isTitleFiledUpdated, isTitleHasSubjects } = this.props;
    if (data && typeof data === 'object') {
      const { isTitleHasSubjects: mainKpIsTitleHasSubjects } = data;
      if (isTitleFiledUpdated) {
        return isTitleHasSubjects;
      } else {
        return mainKpIsTitleHasSubjects
      }
    }
  }

  async componentDidMount() {
    this.setState({
      userId: await getUserId()
    });
    this.getS3FileUploadStatus();
    const { resetDocvizData } = this.props;
    resetDocvizData();
  }

  componentWillUnmount() {

    const {
      KPActions: { clearMetaDataKP },
      callRecommendedTagsReset,
      resetTagsSuggestions,
      updateSubjectSearchResultFlag,
      updateTitleFieldsUpdateState,
      clearAllParentTags
    } = this.props;
    clearMetaDataKP();
    callRecommendedTagsReset();
    resetTagsSuggestions();
    updateSubjectSearchResultFlag(false);
    updateTitleFieldsUpdateState(false);
    clearAllParentTags();
  }
  componentDidUpdate = (prevProps) => {
    const {
      match: {
        params: { step, kpid },
      },
      kpMetaData,
      defaultLanguage,
      taskRegisterData,
      history,
      isPublishContentEntitlement,
      isNextBtnClicked,
      isTitleHasSubjects
    } = this.props;
    const {
      match: {
        params: { step: prevStep },
      },
      defaultLanguage: prevDefaultLanguage,
      kpMetaData: prevKpMetaData,
      taskRegisterData: prevTaskRegisterData,
      isNextBtnClicked: prevIsNextBtnClicked,
      isTitleHasSubjects: prevIsTitleHasSubjects
    } = prevProps;

    if (step !== prevStep) {
      this.setState({
        activeStep1: step === STEP1,
        activeStep2: step === STEP2,
        activeStep3: step === STEP3,
      });
    }
    const { activeStep1, mainKPState } = this.state;
    const newMainKPstate = this.getMainKPstate(kpMetaData, defaultLanguage);
    const prevMainKPstate = this.getMainKPstate(prevKpMetaData, prevDefaultLanguage);

    if (kpMetaData && JSON.stringify(newMainKPstate) !== JSON.stringify(prevMainKPstate)) {
      this.setState({
        mainKPState: {
          ...mainKPState,
          ...newMainKPstate
        }
      });
    }

    if (mainKPState?.id && activeStep1 && !kpid) {
      history.replace({
        pathname: `/kp/metadata/step1/${(mainKPState?.id)}`,
        state: { mode: history.location?.state?.mode },
      });
    }
    const { KNOWLEDGE_STATUS: { UNPUBLISHED, PUBLISHED, ARCHIVED, REVIEW } } = GLOBAL_CONFIG;
    if (mainKPState?.status === PUBLISHED || mainKPState?.status === UNPUBLISHED || mainKPState?.status === ARCHIVED || (mainKPState?.status === REVIEW && isPublishContentEntitlement === false)) {
      this.closeModalW();
      this.openModalPublish();
    }
    // called when differnet file upload
    if (!areObjectsEqual(prevKpMetaData?.attachment, kpMetaData?.attachment) && kpMetaData?.attachment?.documentPath) {
      this.setState({ initiatePolling: true })
    } // called when same file  upload again
    else if ((prevKpMetaData?.documentReplacementDate !== kpMetaData?.documentReplacementDate) && kpMetaData?.documentReplacementDate) {
      const that = this;
      setTimeout(() => {
        that.setState({ initiatePolling: true })
      }, 500)
    }
    if (this.state.fileChanged) {
      this.setState({
        fileChanged: false
      });
    }
    this.isFileUploaded(prevProps);
    if ((taskRegisterData?.task_id !== prevTaskRegisterData?.task_id) && taskRegisterData?.task_id) {
      this.handleChange(taskRegisterData?.task_id, "uniqueId", STEP1, () => { }, true)
    }

    /**
     * Check for unique title confirmation
     */
    if (prevIsNextBtnClicked !== isNextBtnClicked && isNextBtnClicked) {
      this.uniqueTitleCheck(prevProps, this.props)
    }

    /**
     * if KP type change from internal to non internal then we have to remove the description
     * char limit vlidation
     */

    const { isInternal: prevIsInternal } = prevMainKPstate;
    const { isInternal } = newMainKPstate;

    if (prevIsInternal !== isInternal && !isInternal) {
      const { descriptionLength } = this.state;
      this.validateDescription("", "description", this.filterValidationDictionary(STEP1),
        {
          isInternal: isInternal,
          showErrorNotification: false,
          descriptionLength
        });
    }

    if (prevIsTitleHasSubjects !== isTitleHasSubjects) {
      this.handleChange(isTitleHasSubjects, "isTitleHasSubjects", STEP1, null, true);
    }
  };

  uniqueTitleCheck = (prevProps, props) => {
    const { mainKPState: { id, title }, descriptionLength } = this.state;
    const { isUniqueTitle: prevIsUniqueTitle } = prevProps;
    const { isUniqueTitle, resetUniqueTitleConfirmation, history } = props;
    if ((prevIsUniqueTitle !== isUniqueTitle) && isUniqueTitle) {
      this.validateForm(this.filterValidationDictionary(STEP1), STEP1, { isAPIValidation: true, showErrorNotification: true, descriptionLength });
      let titleConfig = {
        showUniqueTitleError: true,
        preserveTitle: title
      }
      this.setState({
        titleConfig
      })
    } else {
      const { UI_URL: { METADATA_KP } } = GLOBAL_CONFIG;
      history.push({
        pathname: METADATA_KP(STEP2, id),
        search: getQueryParamIsRpa(),
        state: { mode: history.location?.state?.mode },
      });
    }
    resetUniqueTitleConfirmation();
  }

  getS3FileUploadStatus = () => {
    const {
      props: {
        match: {
          params: { kpid },
        },
      },
    } = this;
    const { getFileStatus } = this.props;
    if (kpid)
      getFileStatus(kpid);
  }

  handleFileDownload = () => {
    const {
      getKPFile,
      match: {
        params: { kpid },
      },
    } = this.props;
    getKPFile(kpid);
  };

  handleS3Upload = async (folderid_guid) => {
    this.setState({
      kpfileUploadStatus: true,
    });
    const { uploadStart } = this.props;
    const { kpfileState: file, mainKPState: { version } } = this.state;
    let folderId = folderid_guid ? folderid_guid : 'guid';
    let fileextn = getFileExtension(file.name);
    let filename = `${folderId}.${fileextn}`;
    const { resetDocvizData } = this.props;
    resetDocvizData();
    uploadStart({ file, filename, folderId, version });
  };

  isFileUploaded = (prevProps) => {
    const { isFileUploaded, callTaskRegister, callRecommendedTagsReset } = this.props;
    const { isFileUploaded: isFileUploadedPrev } = prevProps;
    const { IS_RECOMMENDED_TAGGING_ON } = GLOBAL_CONFIG;
    if (isFileUploaded?.status && isFileUploadedPrev?.status !== isFileUploaded?.status) {
      const uploadStatus = isFileUploaded.status;
      if (Number(uploadStatus) === 200) {
        const { kpfileState: file, mainKPState: { id, version, documentPath } } = this.state;
        const newDocumentPath = getDocumentPath(file.name, id, version);
        const { CREATION_MODAL_OPTION: { FILE } } = GLOBAL_CONFIG;
        this.handleChange(newDocumentPath, "documentPath", STEP1, () => {
          // check file validation 
          const name = FILE;
          const fileExistsOnBucket = newDocumentPath;
          const uploadFlag = false;
          this.handleInputUpdateError(file, name, { ...validationDictionary[STEP1][name], fileExistsOnBucket, uploadFlag });
          if (documentPath) {
            this.handleChange(getUtcDate(new Date()), "documentReplacementDate", STEP1, () => {
              this.setState({
                kpfileUploadStatus: false,
                isFileExist: true,
                fileChanged: true
              });
            }, true);
          } else {
            this.setState({
              kpfileUploadStatus: false,
              isFileExist: true,
              fileChanged: true
            });
          }
          callRecommendedTagsReset();
          if (IS_RECOMMENDED_TAGGING_ON && isFileTypePPT(file?.name)) {
            callTaskRegister(newDocumentPath);
          }
        }, !documentPath);
      } else {
        const {
          notifyError,
          dismissNotifications,
        } = this.props;
        const emptyFile = {
          name: "",
          size: 0
        };
        this.handleChange(emptyFile, "file", STEP1);
        this.setState({
          kpfileUploadStatus: false,
          kpfileState: emptyFile,
        });
        const { KP: { STEP1: { UPLOAD_ERROR, UPLOAD_ERROR_TITLE } } } = LABELS;
        dismissNotifications();
        setTimeout(() => {
          notifyError(UPLOAD_ERROR_TITLE, UPLOAD_ERROR);
        }, 200)
      }
    }
  }

  updateMeta = async (updatedState, isFile = false, isShowLoader = false) => {
    const {
      KPActions: { saveMetaDataKP }
    } = this.props;
    try {
      // update in db
      const folderid_guid = await saveMetaDataKP(updatedState, isShowLoader);

      // do s3 Bucket Upload, only when isFile is true
      if (!!isFile) {
        this.handleS3Upload(folderid_guid);
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @desc handle the input change
   * @memberof Metadata
   * @param {Object} updatedState
   * @return no return
   */
  updateMetaData(updatedState, isFile) {
    // get the key from actions
    this.updateMeta(updatedState, isFile);
  }

  getFormData = (status = GLOBAL_CONFIG.DRAFT_STATUS, isFinalSubmit = false) => {
    const { mainKPState } = this.state;
    return this.getSendKPstate(mainKPState, status, isFinalSubmit);
  }

  updateErrorState = (errorObj, isShowNotification) => {
    let messages = [];
    this.setState(
      {
        errorState: {
          ...errorObj,
        },
      },
      () => {
        const haveErrors = Object.keys(errorObj).length;
        const {
          notifyWarning,
          dismissNotifications,
        } = this.props;
        if (haveErrors) {
          Object.entries(errorObj).forEach(([key, obj]) => {
            if (obj && obj.errormessage && messages.indexOf(obj.errormessage) === -1) {
              messages.push(obj.errormessage)
            }
          });
          if (isShowNotification) {
            dismissNotifications();
            setTimeout(() => {
              notifyWarning("", messages[0]);
            }, 200)
          }
        } else {
          dismissNotifications();
        }
      }
    );
  };

  validateForm = (dataObj, currentStep, validationObj) => {
    const errors = {};
    for (const [key, obj] of Object.entries(dataObj)) {
      const { mainKPState, kpfileUploadStatus } = this.state;
      const inputValue = mainKPState[key];
      if (key === 'file') {
        const { fileUploadInProgress } = this.props;
        const fileExistsOnBucket = mainKPState.documentPath;
        const uploadFlag = !!fileUploadInProgress || !!kpfileUploadStatus;
        obj.fileExistsOnBucket = fileExistsOnBucket;
        obj.uploadFlag = uploadFlag;
      }

      const { isValid, message } = isValidInput(inputValue, obj, mainKPState, validationObj);

      if (!isValid) {
        errors[key] = {
          errormessage: message,
        };
      }
    }
    const haveErrors = Object.keys(errors).length;
    let showErrorNotification = true;
    if (validationObj && !validationObj.showErrorNotification) {
      showErrorNotification = validationObj.showErrorNotification
    }
    this.updateErrorState(errors, showErrorNotification);
    return !haveErrors;
  };

  zoomRightSec = (isZoomRightSec) => {
    // this.setState({
    //   isZoomRightSec,
    // });
  };

  autoSaveandValidateForm = ({ name, value, step, autoSave, isFileNameOnly }) => {
    const { mainKPState: { status } } = this.state;
    const { CREATION_MODAL_OPTION: { FILE } } = GLOBAL_CONFIG;
    const formData = !!status ? this.getFormData(status) : this.getFormData();

    const isFile = name === 'file' && value.name !== "" && !isFileNameOnly ? true : false;
    if (!!autoSave) {
      this.updateMetaData(formData, isFile);
    }
    if (name !== FILE) {
      this.handleInputUpdateError(value, name, validationDictionary[step][name]);
    }
  }

  handleInputUpdateError = (value, name, obj) => {
    const { mainKPState, descriptionLength } = this.state;
    const { isInternal } = mainKPState
    const { isValid, message } = isValidInput(
      value,
      obj,
      mainKPState,
      {
        isInternal,
        descriptionLength
      }
    );
    const { errorState } = this.state;
    let temp = JSON.parse(JSON.stringify(errorState));

    if (isValid) {
      delete temp[name];
      this.updateErrorState(temp, false);
    } else if (Object.keys(errorState).length) {
      temp[name] = { errormessage: message };
      this.updateErrorState(temp, false);
    }
  }

  replaceSplChars = (file) => {
    const replacingCharacters = [',', ';'];
    const modifiedFileName = replaceCharacters(replacingCharacters, file?.name);
    return {
      name: modifiedFileName,
      size: file?.size
    };
  }

  /**
   * 
   * @param {* it should be string which should be a property of mainKPState } objName 
   * @param {array of keys} objKeys 
   */
  updateMainKPStateObjPropData = (objName, objKeys) => {
    let { mainKPState, descriptionLength } = this.state;
    const { caseNumbers = [] } = mainKPState;
    let data = copyObject(mainKPState[objName]);
    if (data && Array.isArray(data)) {
      objKeys.map(item => {
        data[0][item] = [];
        return item;
      })
    }
    /**
     * In above line data is array
     * but if data is obj then add else if(typeof data == 'object)
     * add your own conditions in it
     */

    mainKPState[objName] = data;
    this.setState({
      mainKPState
    }, () => {
      if (caseNumbers.length === 0) {
        const { mainKPState: { status } } = this.state;
        const formData = this.getFormData(status);
        this.updateMetaData(formData, false);
      }
    })

    /**
     * to show description validation when chaning from non internal to internal
     */
    this.handleOnDescriptionChange(descriptionLength)
  }

  handleChange = (data, keyName, step, callback = null, autoSave = true) => {
    let value = data;
    let name = keyName;

    const { mainKPState } = this.state;
    const isDocumentPath = name === "documentPath";
    const isFileNameOnly = name === 'filename';
    if (isFileNameOnly) {
      name = 'file';
    }
    // We do not need entire file object in mainKPState so we save file in seperate state so that upload service can use it
    if ((name === 'file' && value.name !== "")) {
      if (!isFileNameOnly) {
        this.setState(
          {
            kpfileState: value,
            kpfileUploadStatus: true,
            initiatePolling: false
          }
        );
      }
      value = this.replaceSplChars(value);
    } else if (isDocumentPath) {
      this.setState(
        {
          kpfileUploadStatus: true,
          initiatePolling: false
        }
      );
    }

    let newValueObj = {};
    if (value !== mainKPState[name] || isDocumentPath) {

      newValueObj[name] = value;
      //set the state finally
      this.setState(
        {
          mainKPState: { ...mainKPState, ...newValueObj },
        },
        () => {
          this.autoSaveandValidateForm({ name, value, step, autoSave, isFileNameOnly });
          if (typeof callback == "function") {
            callback();
          }
        }
      );
    }

    if (name === 'title') {
      const {
        TBDBS: {
          SEARCH_SUBJECT
        }
      } = CONSTANTS;

      const { getTypeSearchData, isTitleHasSubjects, updateSubjectSearchResultFlag, updateTitleFieldsUpdateState } = this.props;
      let nonStopWords = findNonStopWords(data);
      nonStopWords = nonStopWords.filter(item => item.length > 2);
      if (nonStopWords && Array.isArray(nonStopWords) && nonStopWords.length > 0) {
        getTypeSearchData(nonStopWords, [{ searchKey: SEARCH_SUBJECT }]);
        updateTitleFieldsUpdateState(true);
      }
      /**
       * This conditiona will only call if isTitleHasSubjects is true and 
       * user is updating the title field and title length is less then 3
       */
      if (((nonStopWords && Array.isArray(nonStopWords) && nonStopWords.length === 0) || data.length < 3) && isTitleHasSubjects) {
        updateSubjectSearchResultFlag(false);
        updateTitleFieldsUpdateState(true);
      }
    }
  };

  filterValidationDictionary = (step) => {
    const { activeStep1, mainKPState: { isURLAdded, isInternal, status }, isApprovedByMdpShow } = this.state;
    const { KNOWLEDGE_STATUS: { REVIEW }, IS_MDP_APPROVED_NEWFIELD_KP } = GLOBAL_CONFIG;
    let tempDict = JSON.parse(JSON.stringify(validationDictionary[step]));
    if (activeStep1) {
      if (isURLAdded === '0') {
        delete tempDict['materialUrl'];
      } else if (isURLAdded === "1") {
        delete tempDict['file'];
      }
    }
    if (isInternal) {
      delete tempDict['officeLocation'];
      delete tempDict['regions'];
    } else {
      delete tempDict['internalOfficeLocation'];
    }

    if (!isApprovedByMdpShow || status === REVIEW || !IS_MDP_APPROVED_NEWFIELD_KP) {
      delete tempDict['isMdpApprovalRequired'];
    }
    return tempDict;
  };

  /**
   * @description function is used when user click on publish or review button on step 3
   * if warning validation pop up comes then user will see the error buttons
   * when user click on any button they will redirected to respective page thrugh "Link"
   * as soon as the user enter on the respective page componentDid Mount call where we are calling
   * this function which checks if validate str is pass in the url or not, if yes then
   * we have to call the validation function again to show the errors on respective page
   * @param {*} stepName 
   */
  validationCheck = async (stepName) => {
    const { history } = this.props;
    const { location: { pathname } } = history;
    const { mainKPState } = this.state;
    const { UI_URL: { METADATA_KP } } = GLOBAL_CONFIG;

    if (pathname) {
      let validateStr = pathname.split('/');
      validateStr = validateStr[validateStr.length - 1];
      if (validateStr === VALIDATION_KEY) {
        let obj = {
          preventDefault: () => { },
          stopPropagation: () => { }
        }
        await this.handleServerSideValidation(obj);
        history.replace({
          pathname: METADATA_KP(stepName, mainKPState?.id),
          state: { mode: history.location?.state?.mode }
        });
      }
    }
  }

  handleServerSideValidation = async () => {
    const { activeStep1, activeStep2, activeStep3, mainKPState: { id, title, isInternal }, descriptionLength } = this.state;
    const { getUniqueTitleConfirmation } = this.props;
    if (activeStep1) {
      const isValid = this.validateForm(this.filterValidationDictionary(STEP1), STEP1, {
        isInternal,
        descriptionLength,
        showErrorNotification: true
      });
      if (isValid) {
        await getUniqueTitleConfirmation(id, title);
      }
    } else if (activeStep2) {
      this.validateForm(this.filterValidationDictionary(STEP2), STEP2);
    } else if (activeStep3) {
      this.validateForm(this.filterValidationDictionary(STEP3), STEP3);
    }
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { activeStep1, activeStep2, activeStep3, mainKPState: { id, title, isInternal }, descriptionLength } = this.state;
    let screenName = "";
    let triggerName = "";

    let step1NextBtnClicked = false;

    const { history, getUniqueTitleConfirmation } = this.props;
    if (activeStep1) {
      const isValid = this.validateForm(this.filterValidationDictionary(STEP1), STEP1, {
        isInternal,
        descriptionLength,
        showErrorNotification: true
      });
      if (isValid) {
        /**
        * Need to call a api here to check for unique title
        * if api return success then we need to call this below line
        * otherwise we need to show the error in title field i.e
        * ("Unique title is Required")
        */
        await getUniqueTitleConfirmation(id, title);
      } else {
        step1NextBtnClicked = true;
      }
      screenName = SCREEN_NAMES.KP_STEP_1;
      triggerName = TRIGGERS.NEXT;
    } else if (activeStep2) {
      const isValid = this.validateForm(this.filterValidationDictionary(STEP2), STEP2);
      if (isValid) {
        history.push({
          pathname: `/kp/metadata/step3/${id}`,
          search: getQueryParamIsRpa(),
          state: { mode: history.location?.state?.mode },
        });
      }
      screenName = SCREEN_NAMES.KP_STEP_2;
      triggerName = TRIGGERS.NEXT;
    } else if (activeStep3) {
      const isValid = this.validateForm(this.filterValidationDictionary(STEP3), STEP3);
      if (isValid) {
        this.openModalW();
      }
      screenName = SCREEN_NAMES.KP_STEP_3;
      triggerName = TRIGGERS.PUBLISH;
    }

    this.setState({
      step1NextBtnClicked
    })

    if (!activeStep3) {
      ANALYTICS.kc.sendEventData({
        page: {
          pageInfo: {
            pageName: PAGES.KP_PAGES
          },
          category: {
            primaryCategory: PRIMARY_CATEGORY.KP
          }
        },
        collection: {
          trigger: triggerName,
          screenName: screenName,
        },
        KCpage: {
          id: id
        }
      }, DTMRULE.KP);
    }
  };

  handleSaveDraft = () => {
    const formData = this.getFormData();
    this.updateMeta(formData);
  }

  handleCloneOnFooter = (e) => {
    this.cloneHandler(true)
  }

  handleAnalyticsUpload = (isFileSupp, hasFile) => {
    const { mainKPState: { id } } = this.state;

    // Adobe Analytics event227 - Replace File
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: !!hasFile ? TRIGGERS.REPLACE_FILE : TRIGGERS.UPLOAD_FILE,
        screenName: isFileSupp ? SCREEN_NAMES.REPLACE_KP_STEP_1 : SCREEN_NAMES.REPLACE_UNSUP_KP_STEP_1,
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.KP);
  }

  handleBack = () => {
    const { activeStep1, activeStep2, activeStep3, mainKPState: { id } } = this.state;
    const { history, dismissNotifications } = this.props;
    let screenName = "";

    if (activeStep1) {
      history.push({
        pathname: GLOBAL_CONFIG.UI_URL.CREATION,
        state: { mode: history.location?.state?.mode },
      });
      screenName = SCREEN_NAMES.KP_STEP_1;
    } else if (activeStep2) {
      history.push({
        pathname: `/kp/metadata/step1/${id}`,
        search: getQueryParamIsRpa(),
        state: { mode: history.location?.state?.mode },
      });
      screenName = SCREEN_NAMES.KP_STEP_2;
    } else if (activeStep3) {
      history.push({
        pathname: `/kp/metadata/step2/${id}`,
        search: getQueryParamIsRpa(),
        state: { mode: history.location?.state?.mode },
      });
      screenName = SCREEN_NAMES.KP_STEP_3;
    }
    dismissNotifications();

    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES,
          // pageURL: ""
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: TRIGGERS.BACK,
        screenName: screenName,
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.KP);
  }

  handlePreview = () => {
    const { mainKPState: { id } } = this.state;
    const { history } = this.props;
    const isValid = this.validateForm(this.filterValidationDictionary(STEP3), STEP3);
    if (isValid) {
      history.push({
        pathname: GLOBAL_CONFIG.UI_URL.KP_PREVIEW_DETAILS(id)
      });
    }
  }

  footerButtonsClick = (e) => {
    if (e.target.id !== "btn-next") {
      e.preventDefault();
      e.stopPropagation();
    }
    const isSubmitReview = e.target.id === "btn-review";
    if (e.target.id === "btn-back") {
      this.handleBack();
    } else if (e.target.id === "btn-saveasdraft") {
      this.handleSaveDraft();
    } else if (e.target.id === "btn-previewpage") {
      this.handlePreview();
    } else if (e.target.id === "btn-publish" || e.target.id === "btn-next" || isSubmitReview) {
      this.handleSubmit(e, isSubmitReview);
    } else if (e.target.id === "btn-clone") {
      this.handleCloneOnFooter(e);
    }
  };

  openModalW = () => {
    this.setState({
      isWModalVisible: true
    });
  }

  closeModalW = () => {
    this.handleChange(false, "isPostPublish", STEP3, null, false);
    this.setState({
      isWModalVisible: false,
      comment: ''
    });

  }

  openModalPublish = () => {
    this.setState({
      isPublishModalVisible: true
    });
  }

  closeModalPublish = () => {
    this.setState({
      isPublishModalVisible: false
    });
  }

  getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1?.label_guid === object2?.label_guid;
      });
    });
  }

  getAcceptedTags = (array1, array2) => {
    return array1.filter(object1 => {
      return array2.some(object2 => {
        return object1?.label_guid === object2?.label_guid;
      });
    });
  }

  formatList = (currentList) => {
    let data = [];
    currentList.forEach(object => {
      data.push({
        "label_guid": object?.id || object?.label_guid,
        "label_value": object?.name || object?.label_value
      });
    });
    return data;
  }

  getFeedbackData = (current, suggestion) => {
    let accepted = [];
    let rejected = [];
    let enteredTags = [];
    try {
      const currentList = this.formatList(current);
      const suggestionList = this.formatList(suggestion);
      enteredTags = this.getDifference(currentList, suggestionList);
      rejected = this.getDifference(suggestionList, currentList);
      accepted = this.getAcceptedTags(suggestionList, currentList)
    } catch (e) {
      console.error("getFeedbackData method failed", e);
    }
    return {
      accepted,
      rejected,
      enteredTags
    }
  }

  finalSubmitHandler = () => {
    const { submitFeedbackRecoomendedTags, recommendedTagsDataByFile } = this.props;
    const { mainKPState: { id, isInternal }, comment } = this.state;
    const { KNOWLEDGE_STATUS: { PUBLISHED, REVIEW }, IS_RECOMMENDED_TAGGING_ON } = GLOBAL_CONFIG;
    const isReview = this.isReview();
    const formData = isReview ? this.getFormData(REVIEW, true) : this.getFormData(PUBLISHED, true);
    if (comment)
      formData.comment = comment;
    const { uniqueId, subjects } = formData;
    const { Subject: subjectSuggestions } = recommendedTagsDataByFile;

    if (IS_RECOMMENDED_TAGGING_ON && uniqueId && uniqueId !== "0" && !!subjects?.length && !!subjectSuggestions?.length && !isReview && !isInternal) {
      const feedbackData = this.getFeedbackData(subjects, subjectSuggestions);
      submitFeedbackRecoomendedTags({
        taskId: uniqueId,
        type: "S",
        feedbackData
      });
    }
    this.updateMeta(formData, false, true);
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: this.isReview() ? TRIGGERS.SEND_FOR_REVIEW : TRIGGERS.PUBLISH,
        screenName: SCREEN_NAMES.KP_STEP_3,
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.KP);
  }

  cloneHandler = (isOpen) => {
    this.setState({
      showCloneModal: isOpen
    });
  }

  routeToContribution = () => {
    const { history } = this.props;
    const { MYNAVIGATOR_TABS: { CONTRIBUTIONS } } = CONSTANTS;
    history.push({
      pathname: GLOBAL_CONFIG.UI_URL.HOME,
      search: `?tabselected=${CONTRIBUTIONS}`
    });
  }

  routeToHomePage = () => {
    const { history } = this.props;
    history.push({
      pathname: GLOBAL_CONFIG.UI_URL.HOME
    });
  }

  setStateData = (key, data) => {
    this.setState({ [key]: data })
  }

  cloneSubmitCallback = () => {
    const { mainKPState: { id } } = this.state;
    // Adobe Analytics event261 - clone KP
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_BUTTON_CLICK);
  }

  closeCloneModalCallback = () => {
    const { mainKPState: { id } } = this.state;
    this.cloneHandler(false);
    // Adobe Analytics event262 - clone cancel click
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.KP_PAGES
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_CANCEL_BUTTON_CLICK);
  }

  handleToggleSwitchChange = (isToggle) => {
    this.handleChange(isToggle, "isPostPublish", STEP3, null, false);
  }

  /**
   * Creating steps data for warning pop up modal
   * @param {[{title: 'STEP 1', isSuccess: false }]} stepsList 
   * @returns 
   */
  creatingWarningStepsData = (stepsList = []) => {
    let updatedStepsList = [];
    if (stepsList.length > 0) {
      stepsList.map((item, index) => {
        const { title, isSuccess, link } = item;
        const stepsDefaultObj = {
          buttonText: title,
          buttonIcon: isSuccess ? SuccessIcon : WarningIcon,
          isSuccess: isSuccess ? true : false,
          link,
          iconAltText: isSuccess ? 'success icon' : 'warning icon',
          showDivider: (index < stepsList.length - 1) ? true : false
        }
        updatedStepsList.push(stepsDefaultObj)
        return item;
      })
    }
    return updatedStepsList;
  }

  /**
   * This function will will call when user tries to close the warning modal by
   * clicking on the the cancel button
   * on canceling the modal we have to reset the warningModal value with default value in reducer.
   * After that we have to open the last proceed to confirm modal
   */
  cancelWarningModal = async () => {
    const { resetWarningModal } = this.props;
    await resetWarningModal();
  }

  goToKpStepsPages = async (link) => {
    const { history, resetWarningModal } = this.props;
    if (link) {
      await resetWarningModal();
      history.push({
        pathname: link,
        search: getQueryParamIsRpa(),
        state: { mode: history.location?.state?.mode },
      });
    }
  }

  /**
   * function used to show the sanitizaition banner on basis of below conditions
   * 1.logged in user title belongs to TITLES_REQUIRED_FOR_MDP_APPROVAL
   * 2. logged in user should not have publish rights
   * 3. User should be in KP step 1 
   */
  setSanitizationFlag = () => {
    let showSanitizationBanner = false;
    const { activeStep1 = false } = this.state;
    const {
      userDetails = {},
      isPublishContentEntitlement = false
    } = this.props;

    const { TITLES_REQUIRED_FOR_MDP_APPROVAL } = GLOBAL_CONFIG;

    if (activeStep1 && userDetails?.title && !isPublishContentEntitlement) {
      const { title } = userDetails;
      if (TITLES_REQUIRED_FOR_MDP_APPROVAL.indexOf(title) > -1) {
        showSanitizationBanner = true
      }
    }

    return showSanitizationBanner;
  }

  validateDescription = (value, name, obj, validationObj) => {
    const { mainKPState } = this.state;
    const inputValue = mainKPState[name];
    const { isValid, message } = isValidInput(inputValue, obj[name], mainKPState, validationObj);

    const { errorState } = this.state;
    let temp = JSON.parse(JSON.stringify(errorState));

    if (isValid) {
      delete temp[name];
      this.updateErrorState(temp, false);
    } else {
      let showMessage = false;
      temp[name] = { errormessage: message };
      this.updateErrorState(temp, showMessage);
    }
  }

  handleOnDescriptionChange = (descriptionLength) => {
    this.setState({
      descriptionLength
    });
  }

  thumbsUpDownAnalyticsCallback = (selectedOptions = THUMBSUP, paRecommended, sectionName = "NA") => {
    const { mainKPState: { id } } = this.state;
    const { RESULT_EXPLICIT_FEEDBACK } = DTMRULE;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;
    const newAdobeData = {
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP_CONSUMPTION,
        },
        pageInfo: {
          pageName: PAGES.KP_CONSUMPTION,
          pageURL: getmyCurrentLocation()
        },
      },
      KCpage: {
        id: id,
      },
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      displayMode: sectionName,
      resultRelevanceRating: selectedOptions
    };

    ANALYTICS.kc.sendEventData(newAdobeData, RESULT_EXPLICIT_FEEDBACK);
  }

  /**
  * Fucntion used to show and hide card scroll view in Docviz
  * @returns bool
  */
  getCardScrollViewStatus = (newDocvizDoc) => {
    try {
      const { mainKPState: { isMDPRestricted } } = this.state;
      const { isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = this.props;
      return getDocvizSupportedFileState({ newDocvizDoc, isMDPRestricted, isPreviewDownloadEntitlement, isPreviewDownloadREntitlement });
    } catch (error) {
      console.log('Error in getCardScrollViewStatus function ', error)
    }
    return false;
  }

  //USed to show nudge on title field at the time of page load
  initializeTitleNudge = (title) => {
    try {
      const {
        TBDBS: {
          SEARCH_SUBJECT
        }
      } = CONSTANTS;

      const { getTypeSearchData, updateTitleFieldsUpdateState } = this.props;
      let nonStopWords = findNonStopWords(title);
      nonStopWords = nonStopWords.filter(item => item.length > 2);
      if (title && title.length > 0) {
        getTypeSearchData(nonStopWords, [{ searchKey: SEARCH_SUBJECT }]);
        updateTitleFieldsUpdateState(true);
      }
    } catch (error) {
      console.log('Error in initializeTitleNudge function ', error);
    }
  }

  checkCardSlideLength = (newDocvizDoc) => {
    try {
      if (newDocvizDoc && newDocvizDoc.slides && newDocvizDoc.slides.length > 2) {
        return true;
      }
    } catch (error) {
      console.log('Error in checkCardSlideLength function ', error);
    }
    return false;
  }

  getFrameworkWarningStatus = (newDocvizDoc) => {
    try {
      const { mainKPState: { isInternal } } = this.state;
      if (newDocvizDoc &&
        this.getCardScrollViewStatus(newDocvizDoc) &&
        !isInternal &&
        newDocvizDoc.frameworkSlides &&
        newDocvizDoc.frameworkSlides.length === 0) {
        return true;
      }
    } catch (error) {
      console.log('Error in getFrameworkWarningStatus function ', error);
    }
    return false;
  }

  setIsApprovedByMdpShow = (isApprovedByMdpShow, callback) => {
    this.setState({
      isApprovedByMdpShow
    }, () => {
      if (callback)
        callback();
    });
  }

  isApprovalMDPFieldShow = () => {
    const { mainKPState: { knowledgeType, status, isMdpApprovalRequired, isInternal }, ownerInfo } = this.state;
    const { userDetails } = this.props;
    const isReview = this.isReview();
    const isMdpOwner = isOwnerMdp(userDetails);
    return conditionsApprovalMdp(isReview, isInternal, isMdpOwner, knowledgeType, status, isMdpApprovalRequired);

  }

  render() {
    const { TOOLTIPS: { FOOTER_CLONE } } = LABELS;
    const { isLoading, isUniqueTitleLoading, isWarningModalVisible, warningModalStepsList, getInRoleData } = this.props;
    const { SITECORE_XM_ENABLED } = GLOBAL_CONFIG;

    const {
      match: { params },
      history,
      fileUploadInProgress,
      isViewEntitlement,
      fileExistsOnBucket,
      getCasesMetaDetails,
      associatedCases,
      isPublishContentEntitlement,
      getTagsSuggestions,
      callTaggingStatus,
      callTaskRegister,
      resetTagsSuggestions,
      kpMetaData,
      docvizDoc,
      tagsSuggestionsList,
      isTagFrameworkEntitlement,
      recommendedTagsDataByFile,
      getKPFile
    } = this.props;

    // label & texts
    const {
      KP: {
        PAGE_TITLE,
        EDIT_PAGE_TITLE,
        REQUIRED_LABEL,
        PILL_SAVING,
        PILL_DRAFT_SAVED,
        PUBLISH_QUESTION,
        REVIEW_QUESTION,
        PILL_PUBLISHED,
        PILL_REVIEW,
        PILL_UNPUBLISHED,
        PUBLISH_REQUIRED_FIELDS_WARNING_MSG,
        SANITIZATION_TITLE
      },
      WORK_SPACE: {
        WARNING_MODAL_CANCEL,
        WARNING_MODAL_CONFIRM,
      },
      MY_NAVIGATOR: {
        ITEM_STATUS: {
          DRAFT, PUBLISH
        },
      },
      PAGETITLE: {
        KB_KP_TC_CONTRIBUTION
      },
      BUTTONS: {
        OK
      }
    } = LABELS;
    const { step, kpid } = params;
    const {
      isZoomRightSec,
      activeStep1,
      activeStep2,
      activeStep3,
      errorState,
      mainKPState,
      kpfileUploadStatus,
      isWModalVisible,
      isPublishModalVisible,
      kpMode,
      isFileExist,
      showCloneModal,
      comment: commentText,
      initiatePolling,
      isApprovedByMdpShow,
      titleConfig,
      step1NextBtnClicked
    } = this.state;
    const isFileExistOnBucket = fileExistsOnBucket[kpid] || isFileExist;
    // Prepare Footer Buttons Config: Hide Show Footer buttons
    const btnsConfig = copyObject(CONFIG.footerWorkflow);
    if (mainKPState.id) {
      btnsConfig[1].children[0].disabled = false;
      btnsConfig[1].children[1].disabled = false;
      btnsConfig[1].children[5].disabled = false;
    }
    if (activeStep1 || activeStep2) {
      btnsConfig[1].children[2].visible = false;
      btnsConfig[1].children[3].visible = false;
      btnsConfig[1].children[4].visible = false;
      btnsConfig[1].children[1].visible = true;
    } else if (activeStep3) {
      btnsConfig[1].children[1].visible = false;
      btnsConfig[1].children[2].visible = isViewEntitlement;

      if (typeof isPublishContentEntitlement !== "undefined" && !isPublishContentEntitlement) {
        btnsConfig[1].children[3].visible = false;
        btnsConfig[1].children[4].visible = true;
      }
      else
        btnsConfig[1].children[3].visible = true;
    }

    /**
     * To disable next button if uniquesTitle Api response is pending
     */
    if (activeStep1 && isUniqueTitleLoading) {
      btnsConfig[1].children[1].disabled = true;
    }

    const isDisplaySection = ((activeStep1 && !kpid) || (activeStep1 && kpid && mainKPState?.id)) || mainKPState?.id;


    const { DRAFT_STATUS, KNOWLEDGE_STATUS: { UNPUBLISHED, PUBLISHED, ARCHIVED, REVIEW } } = GLOBAL_CONFIG;
    let pillContent = "--";
    if (isLoading) {
      pillContent = PILL_SAVING;
    } else if (mainKPState?.status === DRAFT_STATUS) {
      pillContent = PILL_DRAFT_SAVED
    } else if (mainKPState?.status === PUBLISHED) {
      pillContent = PILL_PUBLISHED
    } else if (mainKPState?.status === UNPUBLISHED) {
      pillContent = PILL_UNPUBLISHED
    } else if (mainKPState?.status === REVIEW) {
      pillContent = PILL_REVIEW
    }

    const { file, materialUrl, version, isCMPUser } = mainKPState;
    const fileUploadStatus = !!fileUploadInProgress || !!kpfileUploadStatus;
    const isShowPreviewSection = (!fileUploadStatus && file && file?.name) || (materialUrl.title && materialUrl.address);

    const { KP: { PUBLISHED_MESSAGE, KP_NEW_PUBLISHED_MESSAGE, UNPUBLISHED_MESSAGE, ARCHIVED_MESSAGE, REVIEW_MESSAGE, HOME, CONTRIBUTIONS } } = LABELS;
    let succesMessage = '';
    if (mainKPState?.status === PUBLISHED) {
      succesMessage = SITECORE_XM_ENABLED ? KP_NEW_PUBLISHED_MESSAGE : PUBLISHED_MESSAGE;
    } else if (mainKPState?.status === UNPUBLISHED) {
      succesMessage = UNPUBLISHED_MESSAGE;
    } else if (mainKPState?.status === ARCHIVED) {
      succesMessage = ARCHIVED_MESSAGE;
    } else if (mainKPState?.status === REVIEW) {
      succesMessage = REVIEW_MESSAGE;
    }
    const { getDocvizMetadata } = this.props;
    const {
      API_URL: {
        DOCVIZ_POOLING,
      },
      XA,
      DOCVIZ_POOLING_API_DATA: {
        TIME,
        INITIAL_TIME,
        CALLBACK_KEYS,
        ERROR_MSZ
      } } = GLOBAL_CONFIG;
    const pollingData = {
      url: DOCVIZ_POOLING(kpid, true),
      api_key: XA,
      time: TIME,
      callbackKey: CALLBACK_KEYS,
      initialTime: INITIAL_TIME,
      callback: () => {
        this.setState({
          initiatePolling: false,
        });
        setTimeout(() => {
          getDocvizMetadata(kpid, true)
        }, 500)
      },
      errorMsz: ERROR_MSZ,
      fileDetails: {
        fileName: mainKPState?.file?.name,
        documentPath: mainKPState?.documentPath,
        kpId: mainKPState?.id,
        resultType: "MA"
      }
    }

    //Creating warning steps data
    //This data will come from the reducer side
    const warningStepsData = this.creatingWarningStepsData(warningModalStepsList);

    let newDocvizDoc = docvizDoc;
    if (Object.keys(docvizDoc).length) {
      newDocvizDoc.fileName = mainKPState?.file?.name;
    }
    return (
      <Layout
        id="layout__knowledgepages"
        layoutClass="layout__knowledgepages"
        pillFlag
        // additionalInfoFlag={() => <AdditionalInfo label={LABELS.BUTTONS.ADDITIONAL_INFO} />}
        workflowFlag
        breadCrumbFlag
        bannerFlag
        activeStep={parseInt(step.replace("step", ""))}
        stepper={GLOBAL_CONFIG.KP_WORKFLOW_BAR()}
        contentStage={pillContent}
        productName={LABELS.KNOWLEDGE_PRODUCT_NAME.KP}
        pageTitle={
          history.location?.state?.mode === PUBLISH ||
            history.location?.state?.mode === DRAFT
            ? EDIT_PAGE_TITLE
            : PAGE_TITLE
        }
        showSanitizationBanner={this.setSanitizationFlag()}
        sanitizationTitle={SANITIZATION_TITLE}
      >
        <Helmet>
          <title>{KB_KP_TC_CONTRIBUTION}</title>
        </Helmet>
        {isDisplaySection &&
          (<div className="ui equal width form">
            <div className={`layout__page knowledgepage__wrap layout__fix--inner ${this.setSanitizationFlag() ? 'knowledgepage__customCls' : ''}`}>
              <div
                className={`knowledgepage__leftsec 
                ${this.getCardScrollViewStatus(newDocvizDoc) ? ' ' : 'knowledgepage__leftsec--docvizSec'} 
                ${isZoomRightSec ? "knowledgepage__width-35" : ""}
                ${(activeStep2 && this.getCardScrollViewStatus(newDocvizDoc)) && this.checkCardSlideLength(newDocvizDoc) ? "knowledgepage__leftsec--leftSecHeight" : " "}
                }`}
              >
                <span className="knowledgepage__requiredLabel">
                  {REQUIRED_LABEL}
                </span>
                {activeStep1 && (
                  <KPStep1
                    handleChange={(...params) =>
                      this.handleChange(...params, STEP1)
                    }
                    mainKPState={mainKPState}
                    errorState={errorState}
                    getFormData={this.getFormData}
                    handleS3Upload={this.handleS3Upload}
                    fileUploadStatus={fileUploadStatus}
                    handleAnalyticsUpload={this.handleAnalyticsUpload}
                    kpMode={kpMode}
                    isFileExistOnS3={isFileExistOnBucket}
                    getCasesMetaDetails={getCasesMetaDetails}
                    getTagsSuggestions={getTagsSuggestions}
                    callTaggingStatus={callTaggingStatus}
                    callTaskRegister={callTaskRegister}
                    resetTagsSuggestions={resetTagsSuggestions}
                    associatedCases={associatedCases}
                    titleConfig={titleConfig}
                    recommendedTagsDataByFile={recommendedTagsDataByFile}
                    validationCheck={this.validationCheck}
                    updateMainKPStateObjPropData={this.updateMainKPStateObjPropData}
                    handleOnDescriptionChange={this.handleOnDescriptionChange}
                    descriptionLength={this.state.descriptionLength}
                    thumbsUpDownAnalyticsCallback={this.thumbsUpDownAnalyticsCallback}
                    isLoading={isLoading}
                    step1NextBtnClicked={step1NextBtnClicked}
                    initializeTitleNudge={this.initializeTitleNudge}
                    setIsApprovedByMdpShow={this.setIsApprovedByMdpShow}
                    isApprovalMDPFieldShow={this.isApprovalMDPFieldShow}
                    isApprovedByMdpShow={isApprovedByMdpShow}
                  />
                )}
                {activeStep2 && (
                  <KPStep2
                    handleChange={(...params) =>
                      this.handleChange(...params)
                    }
                    mainKPState={mainKPState}
                    errorState={errorState}
                    getTagsSuggestions={getTagsSuggestions}
                    tagsSuggestionsList={tagsSuggestionsList}
                    validationCheck={this.validationCheck}
                  />
                )}
                {activeStep3 &&
                  <KPStep3
                    mainKPState={mainKPState}
                    handleChange={(...params) =>
                      this.handleChange(...params, STEP3)}
                    errorState={errorState}
                    getTagsSuggestions={getTagsSuggestions}
                    validationCheck={this.validationCheck}
                    callTaggingStatus={callTaggingStatus}
                    isPublishContentEntitlement={isPublishContentEntitlement}
                    thumbsUpDownAnalyticsCallback={this.thumbsUpDownAnalyticsCallback}
                  />}
              </div>

              <div
                className={`knowledgepage__rightsec ${this.getCardScrollViewStatus(newDocvizDoc) ? ' ' : 'knowledgepage__rightsec--docvizSec'} ${isZoomRightSec ? "knowledgepage__width-65" : ""
                  }`}
                onMouseEnter={() => this.zoomRightSec(true)}
                onMouseLeave={() => this.zoomRightSec(false)}
              >
                {
                  // if file exists, show kp side bar
                  // if url type, show kp side bar
                  ((isShowPreviewSection && isFileExistOnBucket && kpMetaData?.attachment.fileName) || kpMetaData?.isURLAdded === '1') &&
                  <KPSideBar
                    kpMetaData={kpMetaData}
                    docvizDoc={newDocvizDoc}
                    pollingData={pollingData}
                    initiatePolling={initiatePolling}
                    isMDPRestricted={mainKPState.isRestricted}
                    startFileDownload={this.handleFileDownload}
                    downloadApplicationName={KNOWLEDGE_PAGE_APP_NAME}
                    activeStep={parseInt(step.replace("step", ""))}
                    isKpContributionPage={true}
                    showFrameworkWarning={this.getFrameworkWarningStatus(newDocvizDoc) && isTagFrameworkEntitlement}
                    isTagFrameworkEntitlement={isTagFrameworkEntitlement}
                    getKPFile={getKPFile}
                  />
                }
              </div>
            </div>
            <FooterWorkflow
              buttons={[...btnsConfig]}
              getEvent={(e) => this.footerButtonsClick(e)}
              tooltipHtml={
                <>
                  <p>{FOOTER_CLONE}</p>
                </>
              }
            />
            {/* Warning modal before confirmation for publish and Review both cases */}
            <WarningModal
              heading={this.isReview() ? REVIEW_QUESTION : PUBLISH_QUESTION}
              showPreviewBar={(SITECORE_XM_ENABLED ? getInRoleData.IsCMPUser : isCMPUser) && !this.isReview() && (version > 1)}
              button1={{
                text: WARNING_MODAL_CANCEL,
                clickHandler: this.closeModalW,
              }}
              button2={{
                text: WARNING_MODAL_CONFIRM,
                clickHandler: this.finalSubmitHandler,
              }}
              isOpen={isWModalVisible}
              commentTextField={this.isReview() ? false : (version > 1)}
              setCommentCallback={this.setStateData}
              previewBarCallback={this.handleToggleSwitchChange}
              comment={commentText}
              onRequestClose={this.closeModalW}
            />

            {/* Waringin popup for required fields for publishing a KP */}
            <WarningModal
              heading={PUBLISH_REQUIRED_FIELDS_WARNING_MSG}
              iconName={'warning'}
              button1={{
                text: OK,
                clickHandler: this.cancelWarningModal,
              }}
              isOpen={isWarningModalVisible}
              KPWarningModalBodySection={<WorkflowWarningSteps stepsConfig={{
                stepsList: warningStepsData,
                cancelOldModal: true,
                cancelOldModalFunc: this.closeModalW,
                btnClickHandler: this.goToKpStepsPages
              }} />}
              onRequestClose={this.cancelWarningModal}
              kbeWarningCustomCls="kbeWarning__customCls"
            />

            {/* Success modal for publish and review */}
            <WarningModal
              overlayClassName={`${showCloneModal ? "knowledgepage__warningwrap--hide" : ""}`}
              heading={succesMessage}
              headingAlign={"left"}
              button1={this.isReview() ? {
                text: CONTRIBUTIONS,
                clickHandler: this.routeToContribution,
                colorClass: "black"
              } : {
                text: SITECORE_XM_ENABLED ? LABELS.BUTTONS.XMC_HOME : HOME,
                clickHandler: SITECORE_XM_ENABLED ? this.routeToContribution : this.routeToHomePage,
                colorClass: "black"
              }}
              customWidth={this.isReview() ? 640 : false}
              button2={this.isReview() ? {
                text: LABELS.BUTTONS.CONTRIBUTE_NEW_KNOWLEDGE,
                clickHandler: () => {
                  history.push(
                    {
                      pathname: GLOBAL_CONFIG.UI_URL.CREATION
                    }
                  );
                },
                colorClass: "green"
              } : {
                text: SITECORE_XM_ENABLED ? LABELS.BUTTONS.CONTRIBUTE_MORE : LABELS.BUTTONS.VIEW_CONSUMPTION,
                clickHandler: () => {
                  history.push(
                    {
                      pathname: (SITECORE_XM_ENABLED ? (GLOBAL_CONFIG.UI_URL.CREATION) : (GLOBAL_CONFIG.UI_URL.KP_CONSUMPTION(mainKPState?.id)))
                    }
                  );
                },
                colorClass: "green"
              }}
              {...(!SITECORE_XM_ENABLED && {
                button3: {
                  text: LABELS.BUTTONS.CLONE,
                  clickHandler: () => this.cloneHandler(true)
                }
              })}

              iconName={'check'}
              isOpen={isPublishModalVisible}
            />
            <CloneModal
              id={mainKPState?.id}
              status={mainKPState?.status}
              showCloneModal={showCloneModal}
              closeModalCallback={this.closeCloneModalCallback}
              cloneSubmitCallback={this.cloneSubmitCallback}
            />
          </div>)
        }
      </Layout>
    );
  }
}

KnowledgePageContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  kpMetaData: kpSelectors.getMetadata(state),
  fileExistsOnBucket: kpSelectors.getPreviewDocumentStatus(state),
  fileUploadInProgress: uploadSelectors.getUploadLoader(state),
  isFileUploaded: uploadSelectors.fileUploadStatus(state),
  isLoading: kpSelectors.getLoading(state),
  defaultLanguage: kpSelectors.getLanguageID(state, "English"),
  associatedCases: caseSelectors.getCasesMetaDetails(state),
  getLanguageData: kpSelectors.getLanguageData(state),
  isViewEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, VIEW),
  docvizDoc: docvizSelectors.getDocvizDocument(state),
  isPublishContentEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
  tagsSuggestionsList: kpSelectors.getTagsSuggestionsList(state),
  isUniqueTitle: kpSelectors.getUniqueTitleConfirmation(state),
  isUniqueTitleLoading: kpSelectors.getUniqueTitleLoading(state),
  isNextBtnClicked: kpSelectors.getNextBtnClicked(state),
  taskRegisterData: recommendedTaggingSelectors.getTaskRegisterData(state),
  recommendedTagsDataByFile: recommendedTaggingSelectors.getRecommendedTagsData(state),
  isWarningModalVisible: kpSelectors.getWarningModalStatus(state),
  warningModalStepsList: kpSelectors.getWarningStepsList(state),
  userDetails: globalSelectors.getUserDetails(state),
  isTitleHasSubjects: kpSelectors.getSubjectSearchStatus(state),
  isTitleFiledUpdated: kpSelectors.getTitleFiledUpdatedStatus(state),
  isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
  isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
  isTagFrameworkEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, TAG_FRAMEWORK),
  getInRoleData: kpSelectors.getInRoleData(state),
});

const mapDispatchToProps = (dispatch) => ({
  // taxonomyActions: bindActionCreators({ ...taxonomyActions }, dispatch),
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  notifyError: (title, msg) => dispatch(notifyError(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
  uploadStart: (data) => dispatch(uploadStart(data)),
  callTaskRegister: (filePath) => dispatch(getTaskRegister(filePath)),
  callRecommendedTagsReset: () => dispatch(recommendedTagsReset()),
  callTaggingStatus: (taskId) => dispatch(getTaggingStatus(taskId)),
  submitFeedbackRecoomendedTags: (params) => dispatch(postFeedbackRecommendedTags(params)),

  resetDocvizData: () => dispatch(resetDocvizMetadata()),
  getCasesMetaDetails: (caseNumbers) => dispatch(getCasesMetaDetails(caseNumbers)),
  KPActions: bindActionCreators({ ...KPActions }, dispatch),
  getUniqueTitleConfirmation: (id, title) => dispatch(getUniqueTitleConfirmation({ id, title })),
  getTagsSuggestions: (caseNumbers, type) => dispatch(getTagsSuggestions({ caseNumbers, type })),
  resetTagsSuggestions: () => dispatch(resetTagsSuggestions()),
  resetUniqueTitleConfirmation: () => dispatch(resetUniqueTitleConfirmation()),
  getFileStatus: (KPId) => dispatch({
    type: KP.GET_FILES_STATUS,
    payload: {
      KPIds: [KPId],
      appName: KNOWLEDGE_PAGE_APP_NAME,
      type: GLOBAL_CONFIG.downloadTypeForKP
    }
  }),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: KNOWLEDGE_PAGE_APP_NAME,
      type: GLOBAL_CONFIG.downloadTypeForKP
    }
  }),
  getDocvizMetadata: (id, delayCall = false) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id, delayCall, isInPreviewMode: true } }),
  resetWarningModal: () => dispatch({ type: KP.RESET_WARNING_MODAL }),
  getTypeSearchData: (term, typeSearchKey) => dispatch({ type: KP.GET_SUBJECTS_SEARCH_DATA, payload: { term, typeSearchKey } }),
  updateSubjectSearchResultFlag: (isTitleHasSubjects) => dispatch(getSubjectSearchSuccess(isTitleHasSubjects)),
  updateTitleFieldsUpdateState: (titleFieldUpdateStatus) => dispatch(setTitleFieldsUpdateState(titleFieldUpdateStatus)),
  clearAllParentTags: () => dispatch(resetAllParentTags()),
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch)
});

export const KnowledgePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(KnowledgePageContainer);

export { KnowledgePageContainer };
