// Constant
import { CASE_METADATA } from "redux/constants";

export const getCaseTimeAndBillingPending = () => ({
    type: CASE_METADATA.GET_CASE_T_AND_B_PENDING,
});

export const getCaseTimeAndBillingSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_T_AND_B_SUCCESS,
    payload: response
});

export const getCaseTimeAndBillingFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_T_AND_B_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseSam = (cid) => ({
    type: CASE_METADATA.GET_CASE_SAM,
    payload: { cid }
});

export const getCaseSamPending = () => ({
    type: CASE_METADATA.GET_CASE_SAM_PENDING,
});

export const getCaseSamSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_SAM_SUCCESS,
    payload: response
});

export const getCaseSamFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_SAM_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseMetadataPending = () => ({
    type: CASE_METADATA.GET_CASE_METADATA_PENDING,
});

export const getCaseMetadataSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_METADATA_SUCCESS,
    payload: response
});

export const getCaseMetadataFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_METADATA_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseConsumptionDataSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_CONSUMPTION_DATA_SUCCESS,
    payload: response
});

export const getCaseConsumptionDataPending = () => ({
    type: CASE_METADATA.GET_CASE_CONSUMPTION_DATA_PENDING,
});

export const getCaseConsumptionDataFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_CONSUMPTION_DATA_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseCmpPending = () => ({
    type: CASE_METADATA.GET_CASE_CMP_PENDING,
});

export const getCaseCmpSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_CMP_SUCCESS,
    payload: response
});

export const getCaseCmpFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_CMP_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const saveCaseMetadataPending = () => ({
    type: CASE_METADATA.SAVE_CASE_METADATA_PENDING,
});

export const saveCaseMetadataSuccess = (response) => ({
    type: CASE_METADATA.SAVE_CASE_METADATA_SUCCESS,
    payload: response
});

export const saveCaseMetadataFailure = (error) => ({
    type: CASE_METADATA.SAVE_CASE_METADATA_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseFPADetailsPending = () => ({
    type: CASE_METADATA.GET_CASE_FPA_DETAILS_PENDING,
});

export const getCaseFPADetailsSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_FPA_DETAILS_SUCCESS,
    payload: response
});

export const getCaseFPADetailsFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_FPA_DETAILS_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const searchCasesPending = () => ({
    type: CASE_METADATA.SEARCH_CASES_PENDING,
});

export const searchCasesSuccess = (response) => ({
    type: CASE_METADATA.SEARCH_CASES_SUCCESS,
    payload: response
});

export const searchCasesFailure = (error) => ({
    type: CASE_METADATA.SEARCH_CASES_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseTypesPending = () => ({
    type: CASE_METADATA.GET_CASE_TYPES_PENDING,
});

export const getCaseTypesSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_TYPES_SUCCESS,
    payload: response
});

export const getCaseTypesFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_TYPES_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getCaseOpenAndCloseDatePending = () => ({
    type: CASE_METADATA.GET_CASE_TB_DATES_PENDING,
});

export const getCaseOpenAndCloseDateSuccess = (response) => ({
    type: CASE_METADATA.GET_CASE_TB_DATES_SUCCESS,
    payload: response
});

export const getCaseOpenAndCloseDateFailure = (error) => ({
    type: CASE_METADATA.GET_CASE_TB_DATES_FAILURE,
    payload: {
        error: error.toString()
    }
});