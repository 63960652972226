
import React, { useCallback, useEffect, useReducer, useState } from "react";
import TermChildren from "./TermChildren";
import { findByPath, rootNode, treeReducer } from "./treeReducer";
import "./treeViewer.scss";
import { useIsMounted } from "./useIsMounted";
import { termIsLeaf } from "./utils";
import { doAxiosRequest } from "config-axios";
import { getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';
import CONFIG from "config";
import { sortOrderForKpType } from 'utils/helpers/helpers';
import { AUTH } from "redux/constants";
import { connect } from "react-redux";
import { selectors as authSelectors } from "redux/reducers/authorization/authorization_reducer";
import { getSmartLogicBearerTokenSuccess } from "redux/actions/authorization/authorization.actions";
/**
 * TreeViewer
 *
 * Internally in the widget terms are stored as a tree, with predefined "root" node. Each term is represented with a TermNode type.
 * See `treeReducer.ts` for all types and operations
 *
 */

/**
 * Convert HierarchyTerm from SES response into a TermNode suitable to be used in the tree.
 *
 * @param sesTerm
 */

const toTermNode = sesTerm => {
  const {
    id,
    name,
    equivalence
  } = sesTerm;
  const isLeaf = termIsLeaf(sesTerm);
  return {
    term: {
      id,
      name,
      equivalence
    },
    isLeaf,
    isOpen: false,
    children: [],
    error: null
  };
};

const TreeViewerContainer = ({
  onClick,
  onError,
  selectedObj,
  callSmartLogicTokenApi,
  isBearerTokenSuccess,
  resetBearerTokenStatus,
  isTokenLoading
}) => {
  const isMounted = useIsMounted();
  const [root, dispatch] = useReducer(treeReducer, rootNode);
  const [localNodePath, setLocalNodePath] = useState(null);

  const loadChildTerms = useCallback(async nodePath => {
    const termId = nodePath.length === 0 ? "88fd0421-7ba0-4066-8631-a2ceb67181f6" : nodePath[nodePath.length - 1];
    let action;

    try {
      const { XA, OKTA: { STK }, API_URL: { GET_SL_PARENT_TAGS } } = CONFIG;
      const smartLogicAcessToken = getFromLocalStorageAsJSON(STK);
      const paramURL = GET_SL_PARENT_TAGS("Content_Type", termId);
      console.log('smartLogicAcessToken', smartLogicAcessToken);
      const axiosConfig = {
        method: 'get',
        endpoint: paramURL,
        headers: {
          'customAuthHeader': true,
          'noAuthHeader': true,
          'authorization': `Bearer ${smartLogicAcessToken}`,
          'x-api-key': XA
        }
      }

      const responseJson = await doAxiosRequest(axiosConfig);
      let childrenData = responseJson.data.terms.map(termWrapper => toTermNode(termWrapper.term))

      /**
       * Sort data for KP type
       */
      childrenData = sortOrderForKpType(childrenData)

      action = {
        type: "SET_CHILDREN",
        nodePath: nodePath,
        children: childrenData
      };

      if (isMounted.current) {
        dispatch(action);
      }

    } catch (error) {
      if (error?.error?.response?.status === 403 || error?.error?.response?.status === 401) {
        await callSmartLogicTokenApi('KP_TYPE_FIELD');
        await setLocalNodePath(nodePath);
      } else {
        let typedError = error;

        if (onError) {
          onError(typedError);
        }

        action = {
          type: "SET_ERROR",
          nodePath: nodePath,
          error
        };
        if (isMounted.current) {
          dispatch(action);
        }
      }
    }

  }, [dispatch, isMounted, onError, callSmartLogicTokenApi]);

  /**
   * useEffect use to lacal loadChildTerms function again only in case
   * when smart logic barer token is expired
   * After calling the function we have to reset the resetBearerToken reducer function
   * as well as clear the local variable localNodePath
   */
  useEffect(() => {
    if (isBearerTokenSuccess && localNodePath && !isTokenLoading) {
      loadChildTerms(localNodePath)
      resetBearerTokenStatus(false)
      setLocalNodePath(null)
    }
  }, [isBearerTokenSuccess, setLocalNodePath, loadChildTerms, localNodePath, resetBearerTokenStatus, isTokenLoading])

  useEffect(() => {
    loadChildTerms([]);
  }, [loadChildTerms]);

  const handleOpen = useCallback(nodePath => {
    const node = findByPath(root, nodePath);

    if (node != null && node.children.length === 0) {
      loadChildTerms(nodePath);
    }

    dispatch({
      type: "OPEN_NODE",
      nodePath
    });
  }, [root, dispatch, loadChildTerms]);
  const handleClose = useCallback(nodePath => {
    const node = findByPath(root, nodePath);

    if (node != null && node.error != null && onError != null) {
      //closing node removes any errors in this node, so call the callback about it;
      onError(null);
    }

    dispatch({
      type: "CLOSE_NODE",
      nodePath
    });
  }, [root, dispatch, onError]);

  return <div className="treeViewer">
    <TermChildren node={root} path={[]} onClick={onClick} onOpen={handleOpen} onClose={handleClose} selectedObj={selectedObj} />
  </div>;
};

const mapStateToProps = (state) => ({
  isBearerTokenSuccess: authSelectors.getTokenSuccessStatus(state),
  isTokenLoading: authSelectors.getTokenLoadingStatus(state)
});


const mapDispatchToProps = (dispatch) => ({
  callSmartLogicTokenApi: (value) => dispatch({ type: AUTH.GET_SMARTLOGIC_BEARER_TOKEN, payload: value }),
  resetBearerTokenStatus: (value) => dispatch(getSmartLogicBearerTokenSuccess(value))
});

export const TreeViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeViewerContainer);