//////////// Importing Constant Files ////////
import { KP, ENTITLEMENTS } from "redux/constants";
import { copyObject } from "utils/helpers/helpers";
import GLOBAL_CONSTANTS from "globalConstants";
import CONFIG from "config";
import { setKpStepsValidationData } from "utils/helpers/validation";

// local variable
export const name = "knowledgePage";

// initial state
export const initialState = {
  metaData: {
    loading: false,
    error: false,
    errorMessage: "",
    data: {},
    setKnowledgeTypeData: [],
    setLanguageData: [],
    kpS3FilesStatus: {},
    publishedData: {},
    downloads: 0,
    checkingS3File: false,
    associatedBundles: [],
    isFetchingAssociatedKBs: false,
    loadingDownloadAll: false
  },
  tagsSuggestions: {
    loading: false,
    error: false,
    data: {},
  },
  uniqueTitle: {
    loading: false,
    error: false,
    uniqueTitleConfirmation: false,
    isNextBtnClicked: false,
    errorMessage: ''
  },
  warningModal: {
    status: false,
    stepsList: []
  },
  subjectSearch: {
    loading: false,
    error: false,
    isSubjectExist: false,
    isTitleFiledUpdated: false
  },
  inRoleData: {
    data: {}
  },
  isKpStep1: false
};
// reducer selectors
export const selectors = {
  getLoading: (state) => state[name].metaData.loading,
  getUniqueTitleLoading: (state) => state[name].uniqueTitle.loading,
  getError: (state) => state[name].metaData.error,
  getErrorMessage: (state) => state[name].metaData.errorMessage,
  getMetadata: (state) => state[name].metaData.data,
  getPublishedMetadata: (state) => state[name].metaData.publishedData,
  getKnowledgeTypeData: (state) => state[name].metaData.setKnowledgeTypeData,
  getPreviewDocumentStatus: (state) => state[name].metaData.kpS3FilesStatus,
  getLanguageData: (state) => state[name].metaData.setLanguageData,
  getDownloadsCount: (state) => state[name].metaData.downloads,
  getTagsSuggestionsList: (state) => state[name].tagsSuggestions.data,
  getInRoleData: (state) => state[name].inRoleData.data,
  getLanguageID: (state, lName) => {
    if (state[name].metaData) {
      const elem = state[name].metaData?.setLanguageData?.filter((item) => { return (item.Name).toLowerCase() === lName.toLowerCase() });
      return {
        id: elem[0]?.Id,
        name: elem[0]?.Name
      }
    }
    return {};
  },
  getAssociatedBundles: (state) => state[name].metaData.associatedBundles,
  getUniqueTitleConfirmation: (state) => state[name].uniqueTitle.uniqueTitleConfirmation,
  getNextBtnClicked: (state) => state[name].uniqueTitle.isNextBtnClicked,
  getWarningModalStatus: (state) => state[name].warningModal.status,
  getWarningStepsList: (state) => state[name].warningModal.stepsList,
  getIsKpStep1: (state) => state[name].isKpStep1,
  getSubjectSearchStatus: (state) => state[name].subjectSearch.isSubjectExist,
  getSubjectSearchLoadingStatus: (state) => state[name].subjectSearch.loading,
  getTitleFiledUpdatedStatus: (state) => state[name].subjectSearch.isTitleFiledUpdated,
  getLoadingDownloadAll: (state) => state[name].metaData.loadingDownloadAll
};

const setMetaDataLoading = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loading: true,
    },
  });
};

const setMetaData = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: false,
      errorMessage: "",
      loading: false,
      data: payload.data,
    },
  });
};

const setInRoleData = (state, payload) => {
  return Object.assign({}, state, {
    inRoleData: {
      ...state.inRoleData,
      data: payload.res
    },
  });
};

const setDownloads = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      downloads: payload.downloads,
    },
  });
};

const setAssociatedBundles = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      associatedBundles: payload,
      isFetchingAssociatedKBs: false,
    },
  });
};

const setAssociatedBundlesLoading = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      isFetchingAssociatedKBs: true,
    },
  });
};

const setAssociatedBundlesError = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: true,
      associatedBundles: [],
      errorMessage: payload.error,
      isFetchingAssociatedKBs: false,
    },
  });
};

const setMetaDataFailure = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...initialState.metaData,
      loading: false,
      error: true,
      ...payload,
    },
  });
};

const setKnowledgeTypeData = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loading: false,
      setKnowledgeTypeData: payload,
    },
  });
};

const setDataLoading = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loading: true,
    },
  });
};

const setDataFailure = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loading: false,
      error: true,
      ...payload,
    },
  });
};

const setLanguageData = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loading: false,
      setLanguageData: payload,
    },
  });
};

const clearKPMetaData = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...initialState.metaData,
      loading: false,
      downloads: 0,
    }
  })
}

const previewDocumentSuccess = (state, payload) => {
  let status = { ...copyObject(state?.metaData?.kpS3FilesStatus) };
  (payload || []).map(({ documentId, exist }) => {
    status[documentId] = exist;
    return status;
  });
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      checkingS3File: false,
      kpS3FilesStatus: status,
    }
  })
}

const previewDocumentFailure = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: true,
      checkingS3File: false,
      errorMessage: payload.error
    }
  })
}

const previewDocumentPending = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      checkingS3File: true,
    }
  })
}


const tagsSuggestionsSuccess = (state, payload) => {
  const { SUGGESTIONS_TEXONOMY, SUGGESTIONS_AUTHORS } = GLOBAL_CONSTANTS;
  const { SUGGESTIONS_TYPE } = CONFIG;
  /**
   * Below code is helping in updating the tagsSuggestions obj
   * with the key which is coming in payload
   */
  let tagsSuggestions = copyObject(state.tagsSuggestions);
  let { data: suggestonsData } = tagsSuggestions;
  const { type, data } = payload;
  if (type === SUGGESTIONS_AUTHORS && Object.keys(suggestonsData).length > 0) {
    const suggestionKey = SUGGESTIONS_TYPE[SUGGESTIONS_AUTHORS];
    suggestonsData[suggestionKey] = data[suggestionKey];
  } else if (type === SUGGESTIONS_TEXONOMY && Object.keys(suggestonsData).length > 0) {
    const suggestionKey = SUGGESTIONS_TYPE[SUGGESTIONS_TEXONOMY];
    suggestonsData[suggestionKey] = data[suggestionKey];
  } else {
    suggestonsData = data;
  }

  return Object.assign({}, state, {
    tagsSuggestions: {
      ...state.tagsSuggestions,
      loading: false,
      error: false,
      data: suggestonsData,
    },
  });
}

const tagsSuggestionsFailure = (state, payload) => {
  return Object.assign({}, state, {
    tagsSuggestions: {
      ...state.tagsSuggestions,
      loading: false,
      data: {},
      ...payload,
    },
  });
}

const tagsSuggestionsPending = (state) => {
  return Object.assign({}, state, {
    tagsSuggestions: {
      ...state.tagsSuggestions,
      error: false,
      loading: true
    },
  });
}

const tagsSuggestionsReset = (state) => {
  return Object.assign({}, state, {
    tagsSuggestions: {
      error: false,
      loading: true,
      data: {}
    },
  });
}


const downloadDocumentFailure = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: true,
      errorMessage: payload.error,
      loadingDownloadAll: false
    }
  })

}

const downloadDocumentPending = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loadingDownloadAll: true
    }
  })
}

const downloadDocumentSuccess = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      loadingDownloadAll: false
    }
  })
}

const resetDownloadError = (state) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: false,
      errorMessage: ""
    }
  })
}

const setPublishedMetadata = (state, payload) => {
  return Object.assign({}, state, {
    metaData: {
      ...state.metaData,
      error: false,
      errorMessage: "",
      loading: false,
      publishedData: payload.data,
    },
  });
}

//Updating value for UniqueTitle
const uniqueTitleConfirmationPending = (state, payload) => {
  return Object.assign({}, state, {
    uniqueTitle: {
      ...state.uniqueTitle,
      loading: true,
      uniqueTitleConfirmation: false,
      isNextBtnClicked: false
    },
  });
}

const uniqueTitleConfirmationSuccess = (state, payload) => {
  const { isduplicateTitle } = payload;
  const { uniqueTitle } = state;
  return Object.assign({}, state, {
    uniqueTitle: {
      ...uniqueTitle,
      loading: false,
      uniqueTitleConfirmation: isduplicateTitle,
      isNextBtnClicked: true
    },
  });
}

const uniqueTitleConfirmationFailure = (state, payload) => {
  const { error } = payload;
  const { uniqueTitle } = state;
  return Object.assign({}, state, {
    uniqueTitle: {
      ...uniqueTitle,
      error: true,
      loading: false,
      isNextBtnClicked: false,
      uniqueTitleConfirmation: false,
      errorMessage: error
    },
  });
}

const resetUniqueTitleConfirmation = (state, payload) => {
  const { uniqueTitle } = state;
  return Object.assign({}, state, {
    uniqueTitle: {
      ...uniqueTitle,
      error: false,
      loading: false,
      isNextBtnClicked: false,
      uniqueTitleConfirmation: false,
      errorMessage: ''
    },
  });
}

const showWarningModal = (state, payload) => {
  const { warningModal } = state;
  //KP_VALIDATION
  const { KP_STEPS_VALIDATION } = GLOBAL_CONSTANTS;
  let stepsList = [];
  stepsList = setKpStepsValidationData(payload, KP_STEPS_VALIDATION)
  return Object.assign({}, state, {
    warningModal: {
      ...warningModal,
      status: true,
      stepsList
    },
  });
}

const resetWarningModal = (state) => {
  const { warningModal } = state;
  return Object.assign({}, state, {
    warningModal: {
      ...warningModal,
      status: false,
      stepsList: []
    },
  });
}


const subjectSearchPending = (state) => {
  const { subjectSearch } = state;
  return Object.assign({}, state, {
    subjectSearch: {
      ...subjectSearch,
      loading: true,
    },
  });
}

const subjectSearchSuccess = (state, payload) => {
  const { subjectSearch } = state;
  return Object.assign({}, state, {
    subjectSearch: {
      ...subjectSearch,
      loading: false,
      error: false,
      isSubjectExist: payload
    },
  });
}

const subjectSearchFailure = (state, payload) => {
  const { error } = payload;
  const { subjectSearch } = state;
  return Object.assign({}, state, {
    subjectSearch: {
      ...subjectSearch,
      loading: false,
      error
    },
  });
}

const titleFieldUpdated = (state, payload) => {
  const { subjectSearch } = state;
  return Object.assign({}, state, {
    subjectSearch: {
      ...subjectSearch,
      isTitleFiledUpdated: payload
    },
  });
}

// reducer
export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // GET METADATA
    case KP.KP_SAVE_PENDING:
      return setMetaDataLoading(state);
    case KP.KP_SAVE_SUCCESS:
      return setMetaData(state, payload);

    case KP.KP_SAVE_FAILURE:
      return setMetaDataFailure(state, payload);


    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_KP_SUCCESS:
      return setInRoleData(state, payload);

    case KP.DROPDOWN_DATA_PENDING:
      return setDataLoading(state);
    case KP.KNOWLEDGE_PAGE_TYPE_DROPDOWN_DATA_SUCCESS:
      return setKnowledgeTypeData(state, payload);
    case KP.DROPDOWN_DATA_FAILURE:
      return setDataFailure(state, payload);
    case KP.KNOWLEDGE_PAGE_LANGUAGE_DROPDOWN_DATA_SUCCESS:
      return setLanguageData(state, payload);
    case KP.KP_CLEAR:
      return clearKPMetaData(state);
    case KP.GET_FILES_STATUS_SUCCESS:
      return previewDocumentSuccess(state, payload);

    case KP.GET_FILES_STATUS_FAILURE:
      return previewDocumentFailure(state, payload);
    case KP.GET_FILES_STATUS_PENDING:
      return previewDocumentPending(state);

    case KP.GET_TAGS_SUGGESTIONS_SUCCESS:
      return tagsSuggestionsSuccess(state, payload);
    case KP.GET_TAGS_SUGGESTIONS_FAILURE:
      return tagsSuggestionsFailure(state, payload);
    case KP.GET_TAGS_SUGGESTIONS_PENDING:
      return tagsSuggestionsPending(state);
    case KP.RESET_TAGS_SUGGESTIONS:
      return tagsSuggestionsReset(state);

    case KP.GET_FILE_PENDING:
      return downloadDocumentPending(state);
    case KP.GET_FILE_FAILURE:
      return downloadDocumentFailure(state, payload);
    case KP.GET_FILE_SUCCESS:
      return downloadDocumentSuccess(state);
    case KP.KP_PUBLISHED_SUCCESS:
      return setPublishedMetadata(state, payload);
    case KP.RESET_DOWNLOAD_ERROR:
      return resetDownloadError(state);
    case KP.GET_DOWNLOADS_SUCCESS:
      return setDownloads(state, payload);
    case KP.GET_DOWNLOADS_FAILURE:
      return downloadDocumentFailure(state, payload);
    case KP.GET_ASSOCIATED_BUNDLES_SUCCESS:
      return setAssociatedBundles(state, payload);
    case KP.GET_ASSOCIATED_BUNDLES_FAILURE:
      return setAssociatedBundlesError(state, payload);
    case KP.GET_ASSOCIATED_BUNDLES_PENDING:
      return setAssociatedBundlesLoading(state);


    case KP.GET_UNIQUE_TITLE_CONFIRMATION_SUCCESS:
      return uniqueTitleConfirmationSuccess(state, payload);
    case KP.GET_UNIQUE_TITLE_CONFIRMATION_FAILURE:
      return uniqueTitleConfirmationFailure(state, payload);
    case KP.GET_UNIQUE_TITLE_CONFIRMATION_PENDING:
      return uniqueTitleConfirmationPending(state);
    case KP.RESET_UNIQUE_TITLE_CONFIRMATION:
      return resetUniqueTitleConfirmation(state);

    case KP.SHOW_WARNING_MODAL:
      return showWarningModal(state, payload);
    case KP.RESET_WARNING_MODAL:
      return resetWarningModal(state);

    case KP.SUBJECTS_SEARCH_PENDING:
      return subjectSearchPending(state);

    case KP.SUBJECTS_SEARCH_SUCCESS:
      return subjectSearchSuccess(state, payload);

    case KP.SUBJECTS_SEARCH_FAILURE:
      return subjectSearchFailure(state, payload);

    case KP.TITLE_FILED_UPDATED:
      return titleFieldUpdated(state, payload);

    default:
      return state;
  }
}
