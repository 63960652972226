// constant
import { CASE_METADATA } from "redux/constants";
// local variable
export const name = "caseMetadata";
// initial state
export const initialState = {
    timeAndBilling: {
        loading: false,
        error: "",
        data: {},
    },
    metadata: {
        loading: false,
        error: "",
        data: {},
        saving: false,
        saveError: "",
    },
    cmp: {
        loading: false,
        error: "",
        data: {
            caseCaptureStatus: '',
            knwOwnerHrId: '',
        },
    },
    fpa: {
        loading: false,
        error: "",
        data: {
            globalImpact: [],
            socialImpact: [],
            climateImpact: []
        },
    },
    search: {
        loading: false,
        error: "",
        data: [],
        count: 0,
    },
    caseTypes: {
        error: "",
        loading: false,
        data: [],
    },
    caseDates: {
        error: "",
        loading: false,
        data: {},
    },
    caseSam: {
        error: "",
        loading: false,
        data: {},
    },
};
// reducer selectors
export const selectors = {
    getTimeAndBillingData: (state) => state[name].timeAndBilling.data,
    getTimeAndBillingLoading: (state) => state[name].timeAndBilling.loading,
    getMetadata: (state) => state[name].metadata.data,
    getCaseOpenAndCloseDate: (state) => state[name].caseDates.data,
    getCaseCaptureStatus: (state) => state[name].cmp.data.caseCaptureStatus,
    getKnwOwnerHrId: (state) => state[name].cmp.data.knwOwnerHrId,
    getMetadataLoading: (state) => state[name].metadata.loading,
    getMetadataSaving: (state) => state[name].metadata.saving,
    getFPAData: (state) => state[name].fpa.data,
    getSearchResults: (state) => state[name].search.data,
    getTotalSearchResults: (state) => state[name].search.count,
    getSearching: (state) => state[name].search.loading,
    getCaseTypes: (state) => state[name].caseTypes.data,
    getCaseSamData: (state) => state[name].caseSam.data,
};

const setCaseTypeLoading = (state) => {
    const { caseTypes } = state;
    return {
        ...state,
        caseTypes: {
            ...caseTypes,
            loading: true,
            error: "",
        }
    }
};

const setCaseTypeError = (state, payload) => {
    const { caseTypes } = state;
    return {
        ...state,
        caseTypes: {
            ...caseTypes,
            loading: false,
            error: payload.error,
        }
    }
};

const setCaseTypeData = (state, payload) => {
    const { caseTypes } = state;
    return {
        ...state,
        caseTypes: {
            ...caseTypes,
            loading: false,
            data: payload.value
        }
    }
};

const setCaseOandCLoading = (state) => {
    const { caseDates } = state;
    return {
        ...state,
        caseDates: {
            ...caseDates,
            loading: true,
            error: "",
        }
    }
};

const setCaseOandCError = (state, payload) => {
    const { caseDates } = state;
    return {
        ...state,
        caseDates: {
            ...caseDates,
            loading: false,
            error: payload.error,
        }
    }
};

const setCaseOandCData = (state, payload) => {
    const { caseDates, caseDates: { data } } = state;
    const { response } = payload;
    const updatedData = { ...data };
    response.forEach(item => {
        updatedData[item.guid] = item;
    })
    return {
        ...state,
        caseDates: {
            ...caseDates,
            loading: false,
            data: updatedData
        }
    }
};

const setMetadataLoading = (state) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            loading: true,
        },
    }
};

const setMetadataError = (state, payload) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            error: payload.error,
            loading: false,
        },
    }
};

const setMetadata = (state, payload) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            data: payload,
            loading: false,
        },
    }
};

const setMetadataSaving = (state) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            saving: true,
            saveError: "",
        },
    }
};

const setSavingError = (state, payload) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            saving: false,
            saveError: payload.error,
        },
    }
};

const setSavingSuccess = (state) => {
    return {
        ...state,
        metadata: {
            ...state.metadata,
            saving: false,
        },
    }
};

const setTBLoading = (state) => {
    const { timeAndBilling } = initialState;
    return {
        ...state,
        timeAndBilling: {
            ...timeAndBilling,
            loading: true,
        },
    }
};

const setTBError = (state, payload) => {
    const { timeAndBilling } = initialState;
    return {
        ...state,
        timeAndBilling: {
            ...timeAndBilling,
            error: payload.error,
        },
    }
};

const setTimeAndBillingData = (state, payload) => {
    const { timeAndBilling } = initialState;
    return {
        ...state,
        timeAndBilling: {
            ...timeAndBilling,
            data: payload,
        },
    }
};


const setCmpLoading = (state) => {
    const { cmp } = initialState;
    return {
        ...state,
        cmp: {
            ...cmp,
            loading: true,
        },
    }
};

const setCmpError = (state, payload) => {
    const { cmp } = initialState;
    return {
        ...state,
        cmp: {
            ...cmp,
            error: payload.error,
        },
    }
};

const setCMPData = (state, payload) => {
    const { cmp } = initialState;
    return {
        ...state,
        cmp: {
            ...cmp,
            data: payload,
        },
    }
};

const setFPALoading = (state) => {
    const { fpa } = initialState;
    return {
        ...state,
        fpa: {
            ...fpa,
            loading: true,
        },
    }
};

const setFPAError = (state, payload) => {
    const { fpa } = initialState;
    return {
        ...state,
        fpa: {
            ...fpa,
            error: payload.error,
        },
    }
};

const setFPAData = (state, payload) => {
    const { fpa } = initialState;
    return {
        ...state,
        fpa: {
            ...fpa,
            data: payload
        },
    }
};

const setSearchLoading = (state) => {
    const { search } = initialState;
    return {
        ...state,
        search: {
            ...search,
            loading: true,
        },
    }
};

const setSearchError = (state, payload) => {
    const { search } = initialState;
    return {
        ...state,
        search: {
            ...search,
            error: payload.error,
        },
    }
};

const setSearchResults = (state, payload) => {
    const { data, count } = payload;
    const { search } = initialState;
    return {
        ...state,
        search: {
            ...search,
            data,
            count,
        }
    }
};

const resetSearchResults = (state) => {
    const { search } = initialState;
    return {
        ...state,
        search,
    }
};




const setCaseSamSuccess = (state, payload) => {
    const { caseSam } = initialState;
    return {
        ...state,
        caseSam: {
            ...caseSam,
            data: payload,
            loading: false,
        },
    }
};

const setCaseSamLoading = (state) => {
    const { caseSam } = initialState;
    return {
        ...state,
        caseSam: {
            ...caseSam,
            loading: true,
        },
    }
};

const setCaseSamError = (state, payload) => {
    const { caseSam } = initialState;
    return {
        ...state,
        caseSam: {
            ...caseSam,
            error: payload.error,
        },
    }
};

export function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CASE_METADATA.GET_CASE_T_AND_B_PENDING:
            return setTBLoading(state);
        case CASE_METADATA.GET_CASE_T_AND_B_FAILURE:
            return setTBError(state, payload);
        case CASE_METADATA.GET_CASE_T_AND_B_SUCCESS:
            return setTimeAndBillingData(state, payload);

        case CASE_METADATA.GET_CASE_SAM_PENDING:
            return setCaseSamLoading(state);
        case CASE_METADATA.GET_CASE_SAM_SUCCESS:
            return setCaseSamSuccess(state, payload);
        case CASE_METADATA.GET_CASE_SAM_FAILURE:
            return setCaseSamError(state, payload);


        case CASE_METADATA.GET_CASE_METADATA_PENDING:
            return setMetadataLoading(state);
        case CASE_METADATA.GET_CASE_METADATA_FAILURE:
            return setMetadataError(state, payload);
        case CASE_METADATA.GET_CASE_METADATA_SUCCESS:
            return setMetadata(state, payload);

        case CASE_METADATA.GET_CASE_CMP_PENDING:
            return setCmpLoading(state);
        case CASE_METADATA.GET_CASE_CMP_FAILURE:
            return setCmpError(state, payload);
        case CASE_METADATA.GET_CASE_CMP_SUCCESS:
            return setCMPData(state, payload);

        case CASE_METADATA.SAVE_CASE_METADATA_PENDING:
            return setMetadataSaving(state);
        case CASE_METADATA.SAVE_CASE_METADATA_FAILURE:
            return setSavingError(state, payload);
        case CASE_METADATA.SAVE_CASE_METADATA_SUCCESS:
            return setSavingSuccess(state);

        case CASE_METADATA.GET_CASE_FPA_DETAILS_PENDING:
            return setFPALoading(state);
        case CASE_METADATA.GET_CASE_FPA_DETAILS_FAILURE:
            return setFPAError(state, payload);
        case CASE_METADATA.GET_CASE_FPA_DETAILS_SUCCESS:
            return setFPAData(state, payload);

        case CASE_METADATA.SEARCH_CASES_PENDING:
            return setSearchLoading(state);
        case CASE_METADATA.SEARCH_CASES_FAILURE:
            return setSearchError(state, payload);
        case CASE_METADATA.SEARCH_CASES_SUCCESS:
            return setSearchResults(state, payload);
        case CASE_METADATA.RESET_SEARCH_RESULTS:
            return resetSearchResults(state);

        case CASE_METADATA.GET_CASE_TYPES_PENDING:
            return setCaseTypeLoading(state);
        case CASE_METADATA.GET_CASE_TYPES_FAILURE:
            return setCaseTypeError(state, payload);
        case CASE_METADATA.GET_CASE_TYPES_SUCCESS:
            return setCaseTypeData(state, payload);

        case CASE_METADATA.GET_CASE_TB_DATES_PENDING:
            return setCaseOandCLoading(state);
        case CASE_METADATA.GET_CASE_TB_DATES_FAILURE:
            return setCaseOandCError(state, payload);
        case CASE_METADATA.GET_CASE_TB_DATES_SUCCESS:
            return setCaseOandCData(state, payload);

        default:
            return state;
    }
}