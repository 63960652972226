import React from 'react';
import PropTypes from "prop-types";
import { ShowMore } from 'components/shared/ShowMore_Less/ShowMore';
// helper
import { formattedDescription } from 'utils/helpers/helpers';
import { Docviz } from "containers/Docviz";
import { PreviewFileConsumption } from 'components/shared/PreviewFileConsumption/PreviewFileConsumption';
import withErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import InfoIcon from "assets/images/info_red.svg";
import Image from "components/shared/Image";
import CONFIG from 'config';
import './ConsumptionDescription.scss';
import LABELS from "labels";

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME }, IS_FRAMEWORK_ENABLED } = CONFIG;

export class ConsumptionDescriptionWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formattedHTML: '',
      isEntitled: true
    };
  }

  setEntitled = (isEntitled) => {
    this.setState({ isEntitled });
  }

  getIsCardScrollView = () => {
    const { docvizDoc, isDocvizNotSupported } = this.props;
    const { isEntitled } = this.state;
    const { DOCVIZ_SCROLL_VIEW_EXTNS } = CONFIG;
    let showUnsupportedPreview = isEntitled && isDocvizNotSupported;
    if (docvizDoc && docvizDoc.attachmentFileType && (DOCVIZ_SCROLL_VIEW_EXTNS.indexOf(docvizDoc.attachmentFileType) > -1) && !showUnsupportedPreview && isEntitled) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const { description } = this.props;
    let formattedDesc = formattedDescription(description);

    this.setState({
      formattedHTML: formattedDesc,
    });
  }

  render() {
    const { formattedHTML } = this.state;
    const {
      isInPreviewMode,
      fileDetails,
      materialUrl,
      previewUrl,
      linkClickAnalytics,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      isMDPRestricted,
      initialHeight,
      needHeaderMargin,
      kpMetaData,
      docvizDoc,
      showMoreButtonCallback,
      isDocvizNotSupported,
      pollingData,
      initiatePolling,
      downloadApplicationName,
      openDocvizOverlay,
      handlePreviewCloseAction,
      showFrameWorkRibbon,
      showReleventMarkers,
      showOnlyDescription,
      showOnlyDocviz,
      getKPFile
    } = this.props;
    const {
      ATTACHMENT_RESTRICTED_MESSAGE
    } = LABELS;
    return (
      <div className={`csdescription ${needHeaderMargin ? ` layout__fix--inner` : ``} `}>
        {!showOnlyDocviz && (
          <div className={"csdescription__col"}>
            <ShowMore height={initialHeight} showMoreButtonCallback={showMoreButtonCallback}>
              <div
                className={'csdescription__description'}
                dangerouslySetInnerHTML={{
                  __html: formattedHTML,
                }}
              ></div>
            </ShowMore>
          </div>
        )}
        {(!showOnlyDescription && (fileDetails?.isFileExist || (!!materialUrl?.title))) &&
          <>
            {
              kpMetaData.isURLAdded === '0' &&
              <div className={!showOnlyDocviz ? "csdescription__preview" : "csdescription__previewdocvizonly"}>
                <Docviz
                  kpMetaData={kpMetaData}
                  docvizDoc={docvizDoc}
                  docvizActions={{
                    showDownload: true,
                    showPreview: true,
                    showEyePreview: false,
                    showShare: false,
                    showEmail: false
                  }}
                  download={fileDetails.startFileDownload}
                  isConsumptionView
                  isDocvizNotSupported={isDocvizNotSupported}
                  pollingData={pollingData}
                  initiatePolling={initiatePolling}
                  isMDPRestricted={isMDPRestricted}
                  downloadApplicationName={downloadApplicationName}
                  openDocvizOverlay={openDocvizOverlay}
                  handlePreviewCloseAction={handlePreviewCloseAction}
                  showFrameWorkRibbon={showFrameWorkRibbon}
                  showReleventMarkers={showReleventMarkers}
                  getKPFile={getKPFile}
                  showCardScrollView={showOnlyDocviz && this.getIsCardScrollView()}
                  showFileHeaderOnScrollView
                  setEntitled={this.setEntitled}
                  isEntitled={this.state.isEntitled}
                  showCardScrollConsumptionView
                  selectable={true}
                  isShowFrameworkStripe={IS_FRAMEWORK_ENABLED}
                />
                {isMDPRestricted && isPreviewDownloadREntitlement &&
                  <div className="csdescription__restricted">
                    <Image src={InfoIcon} />
                    <span>{ATTACHMENT_RESTRICTED_MESSAGE}</span>
                  </div>
                }
              </div>
            }
            {
              kpMetaData.isURLAdded === '1' &&
              <PreviewFileConsumption
                fileDetails={fileDetails}
                isInPreviewMode={isInPreviewMode}
                materialUrl={materialUrl}
                previewUrl={previewUrl}
                isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
                isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
                isMDPRestricted={isMDPRestricted}
                linkClickAnalytics={linkClickAnalytics}
              />
            }
          </>
        }
      </div>
    );
  }
}

ConsumptionDescriptionWrapper.propTypes = {
  description: PropTypes.string.isRequired,
  initialHeight: PropTypes.number,
  needHeaderMargin: PropTypes.bool,
  kpMetaData: PropTypes.shape(),
  docvizDoc: PropTypes.shape(),
  pollingData: PropTypes.shape(),
  downloadApplicationName: PropTypes.string.isRequired,
  openDocvizOverlay: PropTypes.bool,
  handlePreviewCloseAction: PropTypes.func,
  showFrameWorkRibbon: PropTypes.bool,
  showOnlyDescription: PropTypes.bool,
  showOnlyDocviz: PropTypes.bool,
  showReleventMarkers: PropTypes.bool
}

ConsumptionDescriptionWrapper.defaultProps = {
  initialHeight: 352,
  needHeaderMargin: true,
  showOnlyDescription: false,
  showOnlyDocviz: false,
  kpMetaData: {},
  docvizDoc: {},
  pollingData: {},
  downloadApplicationName: KNOWLEDGE_PAGE_APP_NAME,
  openDocvizOverlay: false,
  handlePreviewCloseAction: () => { },
  showFrameWorkRibbon: false,
  showReleventMarkers: false
}

export const ConsumptionDescription = withErrorBoundary(ConsumptionDescriptionWrapper);